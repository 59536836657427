import styled, { css } from "styled-components";

interface TrackDragIndicatorProps {
  lengthing: string;
  start?: string;
}

export const TrackLengthIndicatorWrapperSC = styled.div<TrackDragIndicatorProps>`
  opacity: ${({ lengthing }) => (lengthing === "true" ? 1 : 0)};
  z-index: 3;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
  transition: opacity 0.2s;
  display: flex;
  position: absolute;
  inset: 0 0 0 auto;

  ${({ start }) =>
    start === "true" &&
    css`
      padding-left: 0;
      padding-right: 1em;
      left: 0;
      right: auto;
      border-radius: var(--radius) 0 0 var(--radius);
    `}

  &:hover {
    opacity: 1;
  }
`;

export const LengthIndicatorSC = styled.div<{ start?: string }>`
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  background-color: var(--accent);
  color: #fff;
  cursor: ew-resize;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 100%;
  transition:
    width 0.2s,
    transform 0.2s,
    opacity 0.2s;
  display: flex;
  overflow: hidden;

  ${({ start }) =>
    start === "true" &&
    css`
      border-radius: var(--radius) 0 0 var(--radius);
    `}
`;
