import React, { useContext } from "react";
import { OrderButtons } from "components/elements/OrderButtons";
import { VideosContext } from "providers/VideosProvider/videosProvider";

interface IOrderButtonsProps {}

export const OrdersButtons: React.FC<IOrderButtonsProps> = () => {
  const { state, handles } = useContext(VideosContext);
  const moveUp = (index: number) => {
    if (index === 0) {
      return;
    } // Already at the top
    const updatedItems = [...state.textDataInner];
    [updatedItems[index - 1], updatedItems[index]] = [updatedItems[index], updatedItems[index - 1]];
    handles.updateTextDataList(updatedItems);
  };

  const moveDown = (index: number) => {
    if (index === state.textDataInner.length - 1) {
      return;
    } // Already at the bottom
    const updatedItems = [...state.textDataInner];
    [updatedItems[index], updatedItems[index + 1]] = [updatedItems[index + 1], updatedItems[index]];
    handles.updateTextDataList(updatedItems);
  };

  return (
    <>
      <OrderButtons key={0} id={"0"} onUp={moveUp} onDown={moveDown} index={0} />
      <OrderButtons key={1} id={"1"} onUp={moveUp} onDown={moveDown} index={1} />
    </>
  );
};
