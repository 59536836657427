import React from 'react';
import { HorizontalSlider } from './HorizontalSlider';


export function Speed({ context }: { context: any }) {
  if (!context) {
    return null;
  }

  return (
      <div className="x_flex-h">
        <div className="x_text-08">
          Скорость ({context.state['playbackRate']})
        </div>
        <HorizontalSlider
          width={200}
          min={0.5}
          max={2}
          step={0.5}
          value={context.state['playbackRate']}
          onValueChange={(val: number) => {
            localStorage.setItem('playbackRate', `${val}`);
            context.handles.setPlaybackRate(val);
          }}
        />
      </div>
  )
}