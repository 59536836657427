import React, { useEffect, useContext } from 'react'
import { Head } from './Head';
import { Layout } from './Layout';
import { Context } from '../contextSubtitles';
import { Preloader } from './Preloader';
import { Footer } from './Footer';
import { TrackSubtitles } from './TrackSubtitles';
import { SubtitlesPlayer } from './Subtitles/SubtitlesPlayer';

export function LayoutSubtitles() {
  const context = useContext(Context);
  const { handles } = context;

  useEffect(() => {
    handles.setRussian(true);
    handles.setSubtitleMode(true);
  }, []);

  return (
    <Layout context={context}>
      {Boolean(context.state['mp3']) && <SubtitlesPlayer />}
      <Head context={context} languageShown={true} />
      {!context.state['mp3'] && <Preloader />}
      {Boolean(context.state['mp3']) && <TrackSubtitles />}
      <Footer context={context} view={['zoomLevel', 'speed']} />
    </Layout>
  )
}
