import { Track, Folder, Change } from "../types";

const API_URL = process.env.REACT_APP_API_URL;

export const metadataToSubtitlesLanguages = {
  english: "en-US",
  en: "en-US",
  tr: "tr-TR",
  chinese: "ch-CH",
  ch: "ch-CH",
  es: "es-ES",
  lit: "lit-lit",
  de: "de-de",
  ru: "ru-RU",
}

export const getNextStart = (id: string, dataAll: Folder[], folder: number) => {
  const translations = dataAll[folder] ? dataAll[folder]['translations'] : [];
  const currentIndex = translations.findIndex((one) => one['id'] === id);
  if (currentIndex < translations.length - 1) {
    return translations[currentIndex + 1]['start'];
  }
  return getFolderStartTime(dataAll, folder + 1);
}

export const getFolderStartTime = (dataAll: Folder[], folder: number) => {
  if (folder < dataAll.length) {
    const folderData = dataAll[folder];
    if (folderData && folderData['translations'].length) {
      const prevLength = parseInt(folderData['prev_mp3_length_aggr'], 10);
      const shiftedStart = folderData['translations'][0]['shifted_start'];
      return prevLength + shiftedStart;
    }
  }
  return null;
}

export const checkTrimmed = (tracks: Track[], keepLength: boolean, translationDataAll: Folder[], folder: number) => {
  return tracks.map((oneTrack) => {
    const nextTrackStart = getNextStart(oneTrack['original_id'], translationDataAll, folder);
    const trimmed = getTrimmed(oneTrack, nextTrackStart, 'original_length');

    oneTrack['trimmed'] = trimmed;
    if (!keepLength) {
      oneTrack['length'] = trimmed && nextTrackStart ? nextTrackStart - oneTrack['start'] : oneTrack['original_length']
    }
    return oneTrack
  })
}

const getTrimmed = (oneTrack: Track, nextTrackStart: number | null, lengthFieldName: 'original_length' | 'length') => {
  return nextTrackStart && nextTrackStart < oneTrack['start'] + oneTrack[lengthFieldName]
  ? oneTrack['start'] + oneTrack[lengthFieldName] - nextTrackStart
  : null
}

export const prepareDataOne = (one: Track, folder_num: number, allData: Folder[]) => {
  const folder = folder_num - 1;
  let length;

  // if (!one['length']) {
  //   let calulatedLength = timeStringToMs(one['start_rus']) - timeStringToMs(one['end_rus']);
  //   one['length'] = calulatedLength;
  // };
  if (!one['length'] && one['start_rus_milli'] && one['end_rus_milli']) {
    let calulatedLength = one['end_rus_milli'] - one['start_rus_milli'];
    one['length'] = calulatedLength;
  };

  let addLength = allData[folder] ? parseInt(allData[folder]['prev_mp3_length_aggr']) : 0;
  //const trimmed = getTrimmed(one, nextTrackStart, 'length');

  let start_rus_milli = one['start_rus_milli'] ? one['start_rus_milli'] : one['start']
  start_rus_milli = folder_num ? start_rus_milli - addLength : start_rus_milli
  start_rus_milli = start_rus_milli < 0 ? 0 : start_rus_milli
  const end_rus_milli = calcEndRusMilli(one, addLength);
  const nextTrackStart = getNextStart(one['id'], allData, folder)
  if (nextTrackStart && nextTrackStart < one['start'] + one['length']) {
    length = nextTrackStart - one['start'];
  }
  let trimmed = length ? one['length'] - length : null
  let trimmed_start = null;
  let start = folder_num ? one['start'] - addLength : one['start'];
  if (start < 0) {
    trimmed_start = start * -1;
    start = 0;
  }

  let newDataOne = {
    ...one,
    original_length: one['length'],
    length: length ? length : one['length'],
    trimmed: trimmed,
    trimmed_start: trimmed_start,
    original_start: one['start'],
    original_shifted_start: one['shifted_start'],
    start: start,
    start_rus_milli: start_rus_milli,
    end_rus_milli: end_rus_milli,
    original_id: one['id'],
    id: `${one['folder_num']}_${one['id']}`,
  }

  return newDataOne
}

const calcEndRusMilli = (track: Track, addLength: number) => {
  if (track.length) {
    return track.start + track.length - addLength;
  }
  if (track.end_rus_milli) {
    return track.end_rus_milli - addLength;
  }
  const endRus = convertTime(track.end_rus);
  return endRus - addLength;
}

export const Database = {
  name: "videos",
  version: 4,
  videoTable: "video",
  audioTable: "audio",
};

export const getSpacesApplied = (spaces: Track[], voicingSpacesDeleted: string[]) => {
  return spaces.filter(one => !voicingSpacesDeleted.includes(one.nextTrackId))
    .map((track, index) => {
      if (index === 0) {
        return [0, Number(track.start)]
      } else {
        return [Number(spaces[index - 1].end), Number(track.start)]
      }
    })
};

export const getPrevTrackId = (currentTrackId: string, tracks: Track[]) => {
  const currentIndex = tracks.findIndex(track => track.id === currentTrackId);
  return currentIndex === 0 || currentIndex === -1 ? tracks[0]['id'] : tracks[currentIndex - 1].id;
};

export const convertTime = (time: string) => {
  const arr = time.split(":");
  const res = 
    Number(arr[0]) * 60 * 60 * 1000
    + Number(arr[1]) * 60 * 1000
    + Number(arr[2]) * 1000;
  return res; 
}

const markChangedTracks = (arr: Track[], context: any) => {
  arr.forEach(one => addNewChange(
    {
      id: `${one['folder_num']}_${one['id']}`,
      action: "move"
    },
    "change",
    context
  ));
}

export const querySettings = {
  method: 'POST',
  mode: 'cors' as const,
  cache: 'no-cache' as const,
  credentials: 'same-origin' as const,
  headers: {
    'Content-Type': 'application/json'
  },
  redirect: 'follow' as const,
  referrerPolicy: 'no-referrer' as const,
}

export const postData = async (url = '', data = '') => {
  if (!data) {
    console.log('No data to send');
    return;
  }
  const response = await fetch(url, {...querySettings, body: data });
  return response.json();
}

export const saveCaptionsTranslate = (oneTrack: Track, captions: string, context: any) => {
  const newTrack = {
    folder_num: oneTrack['folder_num'],
    id: oneTrack['original_id'],
    captions
  };
  const jsonString = JSON.stringify(newTrack, null, 2);
  console.log('Sending this (breaking ✂️) = ', jsonString);
  context.handles.setWaitingCut((prev = []) => [...prev, newTrack.id]);
  context.handles.setSavingStatus(true);
  jsonString && postData(`${API_URL}/metadata_edit_subtitle?media=${context.state.mediaId}`, jsonString)
    .then(data => {
      console.log('Server: ', data); // JSON data parsed by `response.json()` call
      context.handles.setSavingStatus(false);

      addNewChange(
        {
          id: oneTrack.id,
          action: "text",
          prevText: oneTrack.captions
        },
        'changedTranslation',
        context
      );
      context.handles.setChangedRecently([oneTrack.id])
      setTimeout(() => {
        context.handles.setChangedRecently([])
      }, 700);

    });
};

export const undoCaptionsTranslate = (oneTrack: Track, captions: string, context: any) => {
  const newTrack = {
    folder_num: oneTrack['folder_num'],
    id: oneTrack['original_id'],
    captions
  };
  const jsonString = JSON.stringify(newTrack, null, 2);
  context.handles.setWaitingCut((prev = []) => [...prev, newTrack.id]);
  context.handles.setSavingStatus(true);
  jsonString && postData(`${API_URL}/metadata_edit_subtitle?media=${context.state.mediaId}`, jsonString)
    .then(data => {
      console.log('Server: ', data); // JSON data parsed by `response.json()` call
      context.handles.setSavingStatus(false);
    });
};

export const saveCaptionsBreak = (
  oneTrack: Track,
  captions: string,
  voice: string,
  speed: string,
  background: string,
  context: any,
) => {
  const newTrack = {
    folder_num: oneTrack['folder_num'],
    id: oneTrack['original_id'],
    captions: captions,
    voice: voice,
    speed: speed,
    add_background: background,
    lang: oneTrack['lang'],
  };
  const jsonString = JSON.stringify(newTrack, null, 2);
  // jsonPreview(jsonString)
  console.log('Sending this (breaking ✂️) = ', jsonString);
  context.handles.setWaitingCut((prev = []) => [...prev, newTrack.id]);
  context.handles.setSavingStatus(true);
  jsonString && postData(`${API_URL}/split_tts_by_br?media=${context.state.mediaId}`, jsonString)
    .then(data => {
      console.log('Server: ', data); // JSON data parsed by `response.json()` call
      context.handles.setSavingStatus(false);
      data = data.map((one: Track) => {
        return prepareDataOne(one, context.state.folder, context.state.translationDataAll)
      });

      markChangedTracks(data, context);
      context.handles.fetchTracks(context.state.mediaId, () => {
        setTimeout(() => {
          addNewChange({ id: data[0].id, action: "text" }, 'changed', context)
          context.handles.setChangedRecently([data[0].id])
        }, 700);
        context.handles.setPop(data[0]['id']);
      })
    });
};

export const saveCaptionsBreakSubtitles = (
  oneTrack: Track,
  captions: string,
  captionsField: string,
  context: any
) => {
  const start = Math.floor(oneTrack.start_rus_milli);
  const end = Math.floor(oneTrack.end_rus_milli);
  const middle = Math.floor(start + (end - start) / 2);
  const newTrack = {
    num: oneTrack['folder_num'],
    id: oneTrack['original_id'],
    [captionsField]: captions,
    new_start_rus_milli: [start, middle + 1],
    new_end_rus_milli: [middle, end],
  };
  const jsonString = JSON.stringify(newTrack, null, 2);
  // jsonPreview(jsonString)
  console.log('Sending this (breaking ✂️) = ', jsonString);
  context.handles.setWaitingCut((prev = []) => [...prev, newTrack.id]);
  context.handles.setSavingStatus(true);
  jsonString && postData(`${API_URL}/metadata_split_subtitle?media=${context.state.mediaId}`, jsonString)
    .then(data => {
      console.log('Server: ', data); // JSON data parsed by `response.json()` call
      context.handles.setSavingStatus(false);
      data = data.map((one: Track) => {
        return prepareDataOne(one, context.state.folder, context.state.translationDataAll)
      });

      markChangedTracks(data, context);
      context.handles.fetchTracks(context.state.mediaId, () => {
        setTimeout(() => {
          addNewChange({ id: data[0].id, action: "text" }, 'changed', context)
          context.handles.setChangedRecently([data[0].id])
        }, 700);
        context.handles.setPop(data[0]['id']);
      })
    });
};

export const addNewChange = (change: Change, what = 'changed', context: any) => {
  context.handles.setChangeToSave(change);

  let setC;
  if (what === 'changed') setC = context.handles.setChanged;
  if (what === 'changedTranslation') setC = context.handles.setChangedTranslation;
  if (what === 'changedRus') setC = context.handles.setChangedRus;
  if (what === 'changedReels') setC = context.handles.setChangedReels;

  if (setC) setC((prevChanged = []) => {
    const newArray: Change[] = [...prevChanged];
    const index = newArray.findIndex(item => item.id === change.id);
    if (index > -1) {
      newArray.splice(index, 1);
    }
    newArray.push(change);
    return newArray;
  });
};

export const removeChange = (change: Change, what = 'changed', context: any) => {
  let setC;
  if (what === 'changed') setC = context.handles.setChanged;
  if (what === 'changedTranslation') setC = context.handles.setChangedTranslation;
  if (what === 'changedRus') setC = context.handles.setChangedRus;
  if (what === 'changedReels') setC = context.handles.setChangedReels;

  if (setC) setC((prevChanged = []) => {
    const newArray: Change[] = [...prevChanged];
    const index = newArray.findIndex(item => item.id === change.id);
    if (index > -1) {
      newArray.splice(index, 1);
    }
    return newArray;
  });
};