import styled from "styled-components";

export const DropdownContainerSC = styled.div`
  width: 30em;
  background: #2a2e4d;
  padding: 0.5em;
  max-height: 30em;
  overflow: auto;
  border-radius: var(--radius);
`;

export const DropdownItemSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #4b4f70;
  margin: 0.2em 0;
  border-radius: var(--radius);
`;

export const DropdownTextWrapperSC = styled.div`
  width: 50%;
  padding: 0.2em;
`;

export const ItemTextSC = styled.span`
  font-size: 14px;
  color: #fff;
`;

export const ImageItemSC = styled.div<{ src: string }>`
  background: ${({ src }) => src && `url(${src})`};
  width: 50%;
  height: 10em;
  object-fit: contain;
  position: relative;
`;

export const UploadButtonContainerSC = styled.div`
  z-index: 1;
  border-radius: var(--radius);
  cursor: pointer;
  background-color: #ffffff4d;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0%;
  opacity: 0;
  &:hover {
    opacity: 1;
    transition: opacity cubic-bezier(0.6, 0.04, 0.98, 0.335) s;
  }
`;
