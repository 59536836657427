import React, { useState, useRef, useEffect, useCallback, useContext, EventHandler } from "react";
import { IVideoData, ITextData, ITrack } from "providers/VideosProvider/types";
import { TrackStartSC } from "components/layouts/videoFull/videoFull.styled";
import { VideosContext } from "providers/VideosProvider/videosProvider";
import { TrackContainerSC, TrackFragmentSC } from "./styled";
import { TrackLengthChangeIndicator } from "components/elements/TrackLengthChangeIndicator";
import { TrackDragIndicator } from "components/elements/TrackDragIndicator";
import { useMouseEvents } from "./hooks/useMouseEvents";

interface ITrackDraggingProps {
  order?: number;
  id: string;
  oneTrack: ITextData | IVideoData;
  children: React.ReactNode;
  setActiveTrack: (id: string) => void;
  activeTrack: string;
  currentIndex: number;
  optionsShown?: string;
}
export const Track: React.FC<ITrackDraggingProps> = React.memo(
  ({
    order = 0,
    id,
    oneTrack,
    currentIndex,
    activeTrack,
    setActiveTrack,
    children,
    optionsShown,
  }) => {
    const { state, handles } = useContext(VideosContext);
    const zoomLevel = 100 - state.zoomLevel;
    const [position, setPosition] = useState(oneTrack.startTime);
    const [lengthPosition, setLengthPosition] = useState(oneTrack.length);
    const [isDragging, setIsDragging] = useState(false);
    const [isLengthing, setIsLengthing] = useState(false);

    const { setStartX, setDragStart, setLengthX } = useMouseEvents({
      isDragging,
      isLengthing,
      oneTrack,
      position,
      zoomLevel,
      videoDuration: state.videoDuration,
      setPosition,
      setLengthPosition,
      lengthPosition,
      setIsDragging,
      setIsLengthing,
      changeOneText: handles.changeOneText,
    });

    const dragHandleRef = useRef<HTMLDivElement>(null);
    const dragHandleRefStart = useRef<HTMLDivElement>(null);
    const lengthHandleRef = useRef<HTMLDivElement>(null);

    const isActive = activeTrack === id;

    useEffect(() => {
      if (oneTrack.length !== lengthPosition) {
        setLengthPosition(oneTrack.length);
      }
    }, [oneTrack.length]);

    const handlePositionMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.type === "touchstart") {
        e.preventDefault();
      }
      if (
        dragHandleRef.current &&
        e.target instanceof Node &&
        dragHandleRef.current.contains(e.target)
      ) {
        setIsDragging(true);
        setStartX(e.clientX);
      }

      if (
        dragHandleRefStart.current &&
        e.target instanceof Node &&
        dragHandleRefStart.current.contains(e.target)
      ) {
        setIsDragging(true);
        setDragStart(true);
        setStartX(e.clientX);
      }
    };

    const handleLengthMouseDown = useCallback(
      (e: React.MouseEvent) => {
        if (e.type === "touchstart") {
          e.preventDefault();
        }
        if (
          lengthHandleRef.current &&
          e.target instanceof Node &&
          lengthHandleRef.current.contains(e.target)
        ) {
          setIsLengthing(true);
          setLengthX(e.clientX);
        }
      },
      [setLengthX],
    );

    const handleActive = (e: React.MouseEvent<HTMLDivElement>) => {
      setActiveTrack(id);
    };

    return (
      <TrackContainerSC
        width={lengthPosition / zoomLevel}
        active={isActive ? "true" : "false"}
        left={position / zoomLevel}
        top={order * 4.2}
        onClick={handleActive}
      >
        {children}
        {(isDragging || isLengthing) && <TrackStartSC />}
        <div onMouseDown={handlePositionMouseDown}>
          {!isDragging && (
            <TrackLengthChangeIndicator
              isLengthing={isLengthing}
              handleLengthMouseDown={handleLengthMouseDown}
              ref={lengthHandleRef}
            />
          )}
          <TrackLengthChangeIndicator isLengthing={isLengthing} ref={dragHandleRefStart} isStart />
          {!isLengthing && <TrackDragIndicator ref={dragHandleRef} />}
          <TrackFragmentSC
            draggable={(isLengthing || isDragging).toString()}
            top={order * 4.2 + 8.4}
          />
        </div>
      </TrackContainerSC>
    );
  },
);
