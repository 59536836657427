import React, { useState } from "react";

import { TrackHoverSC } from "components/layouts/videoFull/videoFull.styled";
import { Track } from "./components/Track";
import { IVideoData, ITextData } from "providers/VideosProvider/types";
import { TrackLineSC } from "./styled";
import { AddItemMenu } from "./components/AddItemMenu";
import { VideoStorage } from "./components/SaveVideo";

interface ITrackDraggingProps<T extends ITextData[] | IVideoData[]> {
  order: number;
  tracks: T;
  isVideo?: boolean;
  children?: React.ReactNode;
}
export const TrackDragging = React.memo(
  <T extends ITextData[] | IVideoData[]>({
    order = 0,
    children,
    tracks,
    isVideo,
  }: ITrackDraggingProps<T>) => {
    const [activeTrack, setActiveTrack] = useState("");
    return (
      <>
        <TrackHoverSC isVideo top={`${order * 4.2}em`} className="TrackHoverSC" />
        <TrackLineSC top={`${order * 4.2}em`}>
          {tracks.map((textData, i) => {
            return (
              <Track
                key={textData.id}
                id={textData.id}
                oneTrack={textData}
                activeTrack={activeTrack}
                setActiveTrack={setActiveTrack}
                currentIndex={i}
              >
                {textData.type === "video" && !textData.isPictureLoaded && (
                  <AddItemMenu show={activeTrack === textData.id} data={textData} />
                )}
                {textData.type === "video" && textData.isPictureLoaded && (
                  <VideoStorage track={textData} />
                )}
              </Track>
            );
          })}
        </TrackLineSC>
      </>
    );
  },
);
