import React, { FormEvent, useState } from "react";

interface ContentEditableProps {
  captions: string;
  className?: string;
  handleInput: (text: string, focused: boolean) => void;
  handleBlur: (text: string) => void;
}

export const ContentEditable: React.FC<ContentEditableProps> = (
    { captions, handleInput, handleBlur, className }
  ) => {
  const [focused, setFocused] = useState(false);
  
  return (<div
    className={className}
    contentEditable
    suppressContentEditableWarning={true}
    onFocus={() => setFocused(true)}
    onInput={(e: FormEvent<HTMLDivElement>) => handleInput((e.target as HTMLTextAreaElement).innerText, focused)}
    onBlur={(e) => {
      setFocused(false);
      handleBlur(e.target.innerText);
    }}
    >{captions}
  </div>)
}