export const captureThumbnail = (videoFile: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.crossOrigin = "anonymous";
    video.currentTime = 1;
    video.muted = true;
    video.playsInline = true;

    video.oncanplay = () => {
      video.play(); // Play a little to ensure frame loads

      setTimeout(() => {
        video.pause();
        const canvas = document.createElement("canvas");
        canvas.width = 150;
        canvas.height = 100;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL("image/png"));
        } else {
          reject("Canvas not supported");
        }

        URL.revokeObjectURL(video.src);
      }, 500); // Small delay to allow frame rendering
    };

    video.onerror = () => reject("Error loading video");
  });
};

export const getVideoDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      resolve(video.duration);
      URL.revokeObjectURL(video.src);
    };

    video.onerror = () => reject("Error loading duration");
  });
};
