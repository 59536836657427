import React, { useRef, useState, useCallback, useContext, useEffect } from 'react';
import { Context } from '../context';
import { Database } from '../utils';
import { GetValue, PutValue, useIndexedDB } from '../hooks/useIndexedDb';
import { ReactWaves } from './ReactWaves';

// Define WaveSurfer type based on known properties
interface WaveSurfer {
  seekTo: (position: number) => void;
  // Add other wavesurfer methods as needed
}

interface PlayerTranslatedProps {
  length: number;
  width: number;
  id: string;
  ind: number;
  handlePlayNext: (ind: number) => void;
  mp3: string;
  end_rus_milli?: number;
  start_rus_milli?: number;
  trimmed?: number | null;
  options?: {
    barHeight: number;
    barRadius: number;
    cursorWidth: number;
    height: number;
    hideScrollbar: boolean;
    progressColor: string;
    responsive: boolean;
    waveColor: string;
    interact: boolean;
    dragSelection: boolean;
    backend?: string;
  };
}

interface AudioCacheItem {
  mediaId: string | null;
  folder: string | null;
  content: Blob;
  id: string;
}

export const PlayerTranslated: React.FC<PlayerTranslatedProps> = React.memo(({
  length,
  width,
  id,
  ind,
  handlePlayNext,
  mp3,
  end_rus_milli,
  trimmed,
  options = {
    barHeight: 2,
    barRadius: 3,
    cursorWidth: 0,
    height: 60,
    hideScrollbar: true,
    progressColor: '#ff7606',
    responsive: true,
    waveColor: '#D1D6DA',
    interact: false,
    dragSelection: false,
    backend: "MediaElement"
  }
}) => {
  const context = useContext(Context);
  const wavesRef = useRef<any>(null);

  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const [pos, setPos] = useState<number>(0);
  const [vaw, setVaw] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadError, setLoadError] = useState<string | null>(null);
  
  // Store object URL to properly clean up later
  const objectUrlRef = useRef<string | null>(null);

  const {
    putValue,
    getValue,
    isDBConnecting,
  } = useIndexedDB(Database.name);

  // Fetch audio data once when component mounts or mp3 changes
  useEffect(() => {
    if (isDBConnecting) return;
    
    let isMounted = true;
    setIsLoading(true);
    setLoadError(null);
    
    const fetchData = async () => {
      try {
        const blob = await getAudioBlob(mp3, putValue, getValue);
        
        if (isMounted) {
          // Revoke previous object URL to prevent memory leaks
          if (objectUrlRef.current) {
            URL.revokeObjectURL(objectUrlRef.current);
          }
          
          // Create and store new object URL
          const newUrl = URL.createObjectURL(blob);
          objectUrlRef.current = newUrl;
          setVaw(newUrl);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching audio:", error);
          setLoadError(error instanceof Error ? error.message : 'Unknown error');
          setIsLoading(false);
        }
      }
    };

    fetchData();
    
    // Clean up function
    return () => {
      isMounted = false;
      // Revoke object URL on unmount
      if (objectUrlRef.current) {
        URL.revokeObjectURL(objectUrlRef.current);
        objectUrlRef.current = null;
      }
    };
  }, [mp3, isDBConnecting]);

  // Get audio blob with caching logic
  const getAudioBlob = async (
    audioUrl: string, 
    putValue: PutValue, 
    getValue: GetValue
  ): Promise<Blob> => {
    try {
      // Try to get from IndexedDB first
      const cachedItem = await getValue(Database.audioTable, audioUrl);
      if (cachedItem && cachedItem.content) {
        return cachedItem.content;
      }
      
      // Fetch if not in cache
      const response = await fetch(audioUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      
      const blob = await response.blob();
      
      // Save to IndexedDB
      await putValue(
        Database.audioTable,
        {
          mediaId: null,
          folder: null,
          content: blob,
          id: audioUrl,
        } as AudioCacheItem
      );
      
      return blob;
    } catch (error) {
      console.error("Error in getAudioBlob:", error);
      throw error;
    }
  };

  // Handle wavesurfer instance when loaded
  const onLoading = useCallback(({ wavesurfer }: { wavesurfer: WaveSurfer }) => {
    setWavesurfer(wavesurfer);
  }, []);

  // Handle clicking on the waveform
  const handleResetAndToggle = useCallback(() => {
    if (wavesurfer && typeof context.state.playStart === 'number' && context.state.playingFragment === id) {
      wavesurfer.seekTo(0);
    }
  }, [wavesurfer, context.state.playStart, context.state.playingFragment, id]);

  // Handle playback position and end behavior
  useEffect(() => {
    // Reset position when track changes or playback starts
    if (context.state.playingFragment || typeof context.state.playStart === 'number') {
      setPos(0);
      if (wavesurfer && context.state['playingFragment'] && typeof context.state.playStart === 'number') {
        wavesurfer.seekTo(0);
      }
    }

    // Setup timeout for trimmed playback
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    if (trimmed && context.state.playingFragment === id) {
      timeoutId = setTimeout(() => {
        handlePlayNext(ind);
      }, length);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    context.state.playingFragment, 
    context.state.playStart, 
    trimmed, 
    id, 
    ind, 
    length, 
    wavesurfer, 
    handlePlayNext
  ]);

  // Handle playback completion
  const handleFinish = useCallback(() => {
    handlePlayNext(ind);
  }, [handlePlayNext, ind]);

  // Prepare final options for ReactWaves
  const waveOptions = {
    ...options,
    audioRate: context.state.playbackRate
  };

  if (isLoading) {
    return (
      <div style={{ paddingTop: '0.26em', width, height: options.height }}>
        <div className="wave-loading">Loading...</div>
      </div>
    );
  }

  if (loadError) {
    return (
      <div style={{ paddingTop: '0.26em', width, color: 'red' }}>
        Error loading audio: {loadError}
      </div>
    );
  }

  return (
    <div
      style={{
        paddingTop: '0.26em',
        width
      }}
      onClick={handleResetAndToggle}
    >
      <div style={{
        top: 0, bottom: 0, left: 0, right: 0, zIndex: 1, position: 'absolute'
      }} />

      {vaw && (
        <ReactWaves
          ref={wavesRef}
          audioFile={vaw}
          className="react-waves"
          options={waveOptions}
          volume={(context.state.volumeTranslated || 0) / 100}
          zoom={(100 - (context.state.zoomLevel || 0)) / 100}
          pos={pos}
          playing={context.state.playingFragment === id}
          onLoading={onLoading}
          onFinish={handleFinish}
        />
      )}
    </div>
  );
});
