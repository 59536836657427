import React from "react";
import { useIndexedDB } from "hooks/useIndexedDb";
import { ButtonWrapperSC, ImageWrapperSC } from "./styled";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Database } from "utils";
import { useVideoContext } from "hooks/useVideoContext";
import { captureThumbnail, getVideoDuration } from "./utils";
import { IVideoData } from "providers/VideosProvider/types";
import { IVideoIndexDB } from "types/common";

interface IVideoStorage {
  track: IVideoData;
}

export const VideoStorage = ({ track }: IVideoStorage) => {
  const { putValue, getAllValue, isDBConnecting } = useIndexedDB(Database.name);
  const { handles, state } = useVideoContext();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      saveVideo(file);
    }
  };

  const saveVideo = async (videoFile: File) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(videoFile);
    reader.onloadend = async () => {
      if (reader.result instanceof ArrayBuffer) {
        try {
          const thumbnail = await captureThumbnail(videoFile);
          const duration = await getVideoDuration(videoFile);

          const videoData: IVideoIndexDB = {
            id: Date.now(),
            content: reader.result,
            type: videoFile.type,
            name: videoFile.name,
          };

          const indexDBId = await putValue(Database.videoTable, videoData);
          handles.changeOneVideoData({
            id: track.id,
            indexDBId: indexDBId as number,
            videoStartTime: 0,
            videoEndTime: duration,
            duration,
            thumbnail,
          });
        } catch (error) {
          console.error("Error processing video:", error);
        }
      }
    };
  };

  return track.thumbnail ? (
    <ImageWrapperSC url={track.thumbnail} />
  ) : (
    <>
      <ButtonWrapperSC htmlFor="video-upload">
        <CloudUploadOutlined />
      </ButtonWrapperSC>
      <input
        id="video-upload"
        type="file"
        accept="video/*"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />{" "}
    </>
  );

  // {/*<div>*/}
  // {/*  {videos.map((video) => (*/}
  // {/*    <video key={video.id} controls>*/}
  // {/*      <source*/}
  // {/*        src={URL.createObjectURL(new Blob([video.content], { type: video.type }))}*/}
  // {/*        type={video.type}*/}
  // {/*      />*/}
  // {/*    </video>*/}
  // {/*  ))}*/}
  // {/*</div>*/}
};
