import React, { useContext } from 'react'
import { Head } from './Head';
import { Context } from '../context';
import { Layout } from './Layout';
import { Tracks } from './Tracks';
import { Preloader } from './Preloader';
import { PlayerVideo } from './PlayerVideo';
import { Subtitles } from './Subtitles';
import { PlayerVideoSimple } from './PlayerVideoSimple';
import { Footer } from './Footer';
import './scrollbar-lite.css';

export function LayoutDragging() {
  const context = useContext(Context);

  return (
    <Layout context={context}>
      <Head context={context} />
      {!context.state['mp3'] && <Preloader />}

      {Boolean(context.state['mp3']) &&
        context.state.videoPlayerType === 'simple' &&
        <PlayerVideoSimple context={context}>
          <Subtitles context={context} />
        </PlayerVideoSimple>}

      {Boolean(context.state['mp3']) &&
        context.state.videoPlayerType === 'advanced' &&
        <PlayerVideo context={context}>
          <Subtitles context={context} />
        </PlayerVideo>}


      {Boolean(context.state['mp3']) && <Tracks />}

      <Footer context={context} view={['audioVolume', 'zoomLevel', 'voicingSpacesMinimum', 'speed']} />
    </Layout>
  )
}
