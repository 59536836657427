import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { OverlayContainerSC } from "./styled";
import { VideosContext } from "providers/VideosProvider/videosProvider";
import { ITextData } from "providers/VideosProvider/types";
import { OverlayText } from "./components/OverlayText";

interface ITextModalState {
  setPlaying: (value: boolean) => void;
}

export const Overlays = React.memo(({ setPlaying }: ITextModalState) => {
  const { state, handles } = useContext(VideosContext);
  const [overlays, setOverlays] = useState<ITextData[]>([]);

  useEffect(() => {
    const visible = state.textDataInner.filter(
      (line) => state.currentTime >= line.startTime && state.currentTime <= line.endTime,
    );
    setOverlays(visible);
  }, [state.currentTime, state.textDataInner]);

  const handleDrag = useCallback(
    (id: string, e: any, position: { x: number; y: number }) => {
      const x = position.x;
      const y = position.y;
      handles.changeOneText({ id, position: { x, y } });
    },
    [handles],
  );

  const handleResize = (id: string, size: { width: number; height: number }) => {
    const width = size.width;
    const height = size.height;
    handles.changeOneText({ id, size: { width, height } });
  };

  return (
    <>
      <OverlayContainerSC>
        {overlays.map((overlay) => (
          <Draggable
            key={overlay.id}
            position={overlay.position || { x: 100, y: 100 }}
            onStop={(e, data) => handleDrag(overlay.id, e, data)}
          >
            <ResizableBox
              width={overlay.size.width}
              height={overlay.size.height}
              minConstraints={[100, 30]}
              maxConstraints={[400, 200]}
              resizeHandles={["se", "sw", "ne", "nw"]}
              onResizeStop={(e, data) =>
                handleResize(overlay.id, {
                  width: data.size.width,
                  height: data.size.height,
                })
              }
            >
              <OverlayText overlay={overlay} setPlaying={setPlaying} />
            </ResizableBox>
          </Draggable>
        ))}
      </OverlayContainerSC>
    </>
  );
});
