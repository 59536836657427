import styled from "styled-components";

export const VideoContainerSC = styled.div`
  z-index: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;

  .track-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 50px) !important;
    z-index: 5;
    background-color: #000;
  }

  .track-player .vjs-control-bar.vjs-big-play-button {
    display: none;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`;
