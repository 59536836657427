import { TooltipH } from "components/UiKit/TooltipH";
import React from "react";
import { ButtonWrapperSC, OrderButtonContainerSC } from "./styled";
import { DownloadMIcon } from "components/elements/icons/DownloadMIcon";
import { PublishMIcon } from "components/elements/icons/PublishMIcon";

export interface IOrderButtonsProps {
  id: string;
  onUp: (id: number) => void;
  onDown: (id: number) => void;
  index: number;
}

export const OrderButtons: React.FC<IOrderButtonsProps> = ({ id, onUp, onDown, index }) => {
  const handleUp = () => {
    onUp(index);
  };
  const handleDown = () => {
    onDown(index);
  };
  return (
    <OrderButtonContainerSC>
      <TooltipH placement="right" title={<>Переместить дорожку вверх</>}>
        <ButtonWrapperSC onClick={handleUp}>
          <PublishMIcon fontSize={"1.6em"} />
        </ButtonWrapperSC>
      </TooltipH>

      <TooltipH placement="right" title={<>Переместить дорожку вниз</>}>
        <ButtonWrapperSC onClick={handleDown}>
          <DownloadMIcon fontSize={"1.6em"} />
        </ButtonWrapperSC>
      </TooltipH>
    </OrderButtonContainerSC>
  );
};
