import React, { useEffect, useRef } from 'react';
import { Video } from './Video';

const API_URL = process.env.REACT_APP_API_URL;

interface PlayerVideoSimpleProps {
  context: any;
  children: React.ReactNode;
}

export function PlayerVideoSimple({ context, children }: PlayerVideoSimpleProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoUrl = context.state.videoUrl ? `${API_URL}/download_rus?file_name=${context.state.mediaId}/${context.state.videoUrl}` : '';

  useEffect(() => {
    if (videoRef.current) {
      if (context.state.playing) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [context.state.playing]);

  useEffect(() => {
      if (videoRef.current) {
        videoRef.current.playbackRate = context.state.playbackRate;
      }
    }, [context.state.playbackRate]);

  // Set the start time of the video based on the 'startTime' prop
  useEffect(() => {
    let startTime = context.state.playOriginalStart;
    if (startTime != null && videoRef.current) {
      videoRef.current.currentTime = startTime / 1000;
    }
  }, [context.state.playOriginalStart]);

  return (
    <div className="c">
      <div className="x_player_video0">
        {children}
        {Boolean(context.state.videoUrl)
          && Boolean(context.state['mp3'])
          && context.state.video
          && <div className="x_player_video_sett0">

            <div className={`x_player_video_set_1 ${context.state.video === 'sm' ? 'active' : ''}`}
              onClick={() => context.handles.setVideo('sm')}
            >
              <div className="ico">
                <div>crop_7_5</div>
              </div>
            </div>

            <div className={`x_player_video_set_1 ${context.state.video === 'md' ? 'active' : ''}`}
              onClick={() => context.handles.setVideo('md')}
            >
              <div className="ico">
                <div>crop_5_4</div>
              </div>
            </div>

            <div className={`x_player_video_set_1 ${context.state.video === 'full' ? 'active' : ''}`}
              onClick={() => context.handles.setVideo('full')}
            >
              <div className="ico">
                <div>fullscreen</div>
              </div>
            </div>

          </div>}

        <Video
          videoRef={videoRef}
          videoUrl={videoUrl}
          videoMode={context.state.video}
          className={`x_player_video x_player_video--${context.state.video}`}
        />
      </div>
    </div>
  );
}
