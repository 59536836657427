import { ImageUploadDropdown } from "./components/ImageUploadDropdown";
import { PlusMIcon } from "components/elements/icons/PlusMIcon";
import React, { useMemo } from "react";
import { AddButtonContainerSC } from "./styled";
import uniqid from "uniqid";
import { IVideoData } from "providers/VideosProvider/types";
import { useVideoContext } from "hooks/useVideoContext";

interface IAddItemMenuProps {
  show: boolean;
  data: IVideoData;
}

export const AddItemMenu: React.FC<IAddItemMenuProps> = ({ show, data }) => {
  const { handles } = useVideoContext();
  const dropDownItems = useMemo(() => {
    const items = data.depiction.map((item) => ({
      key: uniqid("character"),
      text: item[0].toString(),
      value: typeof item[1] === "number" ? item[1] : parseInt(item[1]),
    }));
    return items.sort((a, b) => b.value - a.value);
  }, [data.depiction]);

  const handleClickMenuItem = () => {
    handles.changeOneVideoData({ id: data.id, isPictureLoaded: true });
  };

  return (
    <AddButtonContainerSC show={show.toString()}>
      <ImageUploadDropdown items={dropDownItems} onItemClick={handleClickMenuItem}>
        <div className="x_button x_button--icon">
          <PlusMIcon />
        </div>
      </ImageUploadDropdown>
    </AddButtonContainerSC>
  );
};
