import styled from "styled-components";

export const OrderButtonContainerSC = styled.div`
  grid-column-gap: 0.6em;
  grid-row-gap: 0.6em;
  border-radius: var(--radius);
  color: #ffffff26;
  background-color: #2a2e4d;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 3.6em;
  height: 4em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  transition: color 0.2s;
  display: flex;
  position: relative;

  &:hover {
    color: #ffffff7a;
    background-color: #393e64;
  }
`;

export const ButtonWrapperSC = styled.div`
  cursor: pointer;
  border-radius: 0.2em;
  padding: 0.2em;
  font-size: 14px;

  &:hover {
    color: var(--accent);
  }
`;
