import { TextSC } from "./styled";
import React, { useCallback, useState } from "react";
import { ITextData } from "providers/VideosProvider/types";
import { TextModal } from "../../../../../TextModal";

interface IOverlayTextProps {
  overlay: ITextData;
  setPlaying: (value: boolean) => void;
}

export const OverlayText: React.FC<IOverlayTextProps> = ({ overlay, setPlaying }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditText = () => {
    setIsModalOpen(true);
    setPlaying(false);
  };

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <TextSC
        color={overlay.color}
        fontSize={overlay.fontSize}
        background={overlay.backgroundColor}
        onDoubleClick={handleEditText}
      >
        {overlay.text}
      </TextSC>
      {isModalOpen && (
        <TextModal
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
          initialTextData={overlay}
          isEdit
        />
      )}
    </>
  );
};
