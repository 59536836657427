import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import { Tooltip, Popover } from "antd";
// import { tooltipThemeDefault } from './tooltip_theme.js';

export const tooltipThemeDefault = {
  color: "white",
  key: "white",
  styles: { body: { color: "black" } },
  fontWeightStrong: 400,
};

export const PopoverH = (props) => {
  const { title, tooltipTheme = {} } = { ...props };

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  // return < Tooltip
  //   title={title}
  //   {...tooltipTheme}
  //   {...props}
  // />
  return (
    <Popover
      title={title}
      open={open}
      onOpenChange={handleOpenChange}
      {...tooltipThemeDefault}
      {...tooltipTheme}
      {...props}
    />
  );
};
