export const FRAGMENT_MIN_HEIGHT = 25;
export const MIN_VIDEO_SIZE_HACK = 10000;

export const formatTime = (t) => {
  if (!t) return '';
  t = t.split('.')[0];
  const parts = t.split(':');
  if (parts[0] === '0') parts.shift();
  return parts.join(':');
};

export const formatTimeMilli = (t) => {
  if (t === null || t === undefined) return '';

  // Convert milliseconds to seconds
  const totalSeconds = Math.floor(t / 1000);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Format the output string
  return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};
