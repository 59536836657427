import React, { useState, useEffect, useContext, useRef } from 'react';
import { Context } from '../context';
import { DraggingFragment } from './DraggingFragment';
import { PreloaderGif, PreloaderTracks } from './Preloader';
import { Pop } from './Pop';
import { PlayerMain } from './PlayerMain';
import { Track } from 'types';

// Context interface based on usage in the code
interface ContextState {
  translationData: Track[];
  zoomLevel: number;
  pop: string | null;
  mp3: string | null;
  durationFull: number;
  playbackRate: number;
  scrollTo?: number;
  playStart?: any;
  playing: boolean;
  playingFragment: string | null;
  video?: string;
  [key: string]: any; // For any other properties
}

interface ContextHandles {
  setPop: (id?: string) => void;
  setPlaying: () => void;
  setPlayingFragment: (id?: string) => void;
  setScrollTo: () => void;
  updatePosition: (id: string, newPosition: number, tracks: Track[], subtitles?: any) => void;
  updateLength: (id: string, newLength: number, tracks: Track[], subtitles?: any) => void;
  [key: string]: any; // For any other methods
}

interface ContextType {
  state: ContextState;
  handles: ContextHandles;
}

export const Tracks = React.memo(() => {
  const subtitles = undefined;
  const context = useContext<ContextType>(Context);
  const contextRef = useRef<ContextType>(context);
  const trackRef = useRef<HTMLDivElement>(null);
  const zoomLevel = 100 - context.state['zoomLevel'];
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [tracks, setTracks] = useState<Track[]>(context.state['translationData']);

  const openEditor = (oneTrack: Track): void => {
    console.log('openEditor = ');
    context.handles.setPop(oneTrack['id']);
    context.handles.setPlaying();
    context.handles.setPlayingFragment();

    // Clear the timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }

  useEffect(() => {
    contextRef.current = context;
  }, [context]);

  useEffect(() => {
    if (tracks.length) console.log('🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴');
  }, [tracks]);

  const getNextTrack = (currentIndex: number, tracks: Track[]): Track | null => {
    return currentIndex === tracks.length - 1 ? null : tracks[currentIndex + 1];
  }

  const handlePlayNext = (currentIndex: number): void => {
    // context.handles['setPlayingFragment']();
    setTracks((currentTracks) => {
      if (tracks.length) console.log('Setting tracks from next 🌕🌕🌕🌕🌕🌕');
      const currentTrack = currentTracks[currentIndex];
      const nextTrack = getNextTrack(currentIndex, currentTracks);
      const delay = nextTrack
        ? nextTrack['start'] - (currentTrack['start'] + currentTrack['length'])
        : context.state['durationFull'] - (currentTrack['start'] + currentTrack['length']);

      console.log('delay in ms = ', delay);

      // Only set the current playing fragment here, not the next one yet
      // context.handles['setPlayingFragment'](currentTrack['id']);

      const timeoutId = setTimeout(() => {
        console.log(`Timeout triggered for track ID = ${nextTrack ? nextTrack['id'] : 'none'}`);
        // if (context.state.playing) {
        // Here we're ready to play the next track, so now set the next playing fragment
        if (nextTrack) {
          // Clear the timeout as we're about to play the next track
          clearTimeout(timeoutRef.current!);
          timeoutRef.current = null;

          //contextRef.current.handles['setPlayingFragmentNext']();
          contextRef.current.handles['setPlayingFragment'](nextTrack['id']);
        }
      }, delay / context.state.playbackRate);

      timeoutRef.current = timeoutId;
      // console.log(`Timeout set with ID: ${timeoutId}`);
      return currentTracks;
    });
  };

  useEffect(() => {
    if (typeof context.state.scrollTo === 'number' && trackRef.current) {
      trackRef.current.scrollTo({
        left: context.state.scrollTo,
        behavior: 'smooth'
      });
      context.handles.setScrollTo()
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (typeof context.state.scrollTo === 'number' && trackRef.current) {
      trackRef.current.scrollTo({
        left: context.state.scrollTo,
        behavior: 'smooth'
      });
      context.handles.setScrollTo()
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [context.state.scrollTo]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      // context.handles.setPlayingFragment()
    }
  }, [context.state.playStart]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [context.state.playingFragment]);

  useEffect(() => {
    if (!context.state.playing && timeoutRef.current) {
      // console.log('Clearing timeout:', timeoutRef.current);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    } else {
      console.log('Not clearing timeout. Playing:', context.state.playing, 'Timeout:', timeoutRef.current);
    }
  }, [context.state.playing]);

  const currentPlayingFragmentRef = useRef<string | null>(null);

  useEffect(() => {
    currentPlayingFragmentRef.current = context.state.playingFragment;
  }, [context.state.playingFragment]);

  //const currentPlayingFragmentNextRef = useRef<string | null>(null);

  //useEffect(() => {
  //  currentPlayingFragmentNextRef.current = context.state.playingFragmentNext;
  //}, [context.state.playingFragmentNext]);

  /*useEffect(() => {
    if (trackRef.current) {
      const totalTrackWidth = trackRef.current.scrollWidth;
      const containerWidth = trackRef.current.clientWidth;
      const currentZoomFragment = context.state.zoomFragment[0]; // Assuming this is a time value

      // Calculate the position to scroll to based on the zoomFragment
      const scrollPositionFromZoomFragment = (currentZoomFragment / context.state.durationFull) * totalTrackWidth;

      let newScrollPosition;

      // Decide what should trigger the scroll - the zoomFragment change or the playing fragment
      const targetFragmentId = currentPlayingFragmentNextRef.current || currentPlayingFragmentRef.current;
      if (targetFragmentId) {
        const targetTrack = tracks.find(track => track.id === targetFragmentId);
        if (targetTrack) {
          const targetTrackPosition = (targetTrack.start / context.state.durationFull) * totalTrackWidth;
          newScrollPosition = targetTrackPosition - (containerWidth / 2) + leftPadding + (targetTrack.length / 2 / zoomLevel);
          // newScrollPosition = targetTrackPosition - (containerWidth / 2);
        }
      } else {
        // If no target fragment is set, use the zoomFragment to determine the scroll position
        newScrollPosition = scrollPositionFromZoomFragment - (containerWidth / 2) + leftPadding;
      }

      // trackRef.current.scrollTo({
      //   left: newScrollPosition,
      //   behavior: 'smooth'
      // });
    }
  }, [
    context.state.zoomFragment,
    context.state.durationFull,
    context.state.playingFragment,
    context.state.playingFragmentNext,
    leftPadding,
    tracks,
    zoomLevel
  ]);*/

  useEffect(() => {
    if (context.state['translationData'].length) {
      setTracks([...context.state['translationData']])
    }
  }, [context.state.translationData, tracks.length]);

  const updatePosition = (id: string, newPosition: number, subtitles?: any): void => {
    context.handles.updatePosition(id, newPosition, tracks, subtitles)
  };
  
  const updateLength = (id: string, newLength: number, subtitles?: any): void => {
    context.handles.updateLength(id, newLength, tracks, subtitles)
  };

  const updateTrack = (id: string, newData: Partial<Track>): void => {
    let prevTracks = JSON.parse(JSON.stringify(tracks))
    let newTracks = prevTracks.map((track: Track) =>
      track.id === id ? { ...track, ...newData } : track
    );
    setTracks(newTracks)
  };

  return (
    <>
      {context.state.pop &&
        <Pop
          oneTrackId={context.state.pop}
          handleClose={() => context.handles.setPop()}
          context={context}
        />
      }

      <div className="x_tracks0">
        {!context.state['mp3'] && <PreloaderTracks>
          <PreloaderGif />
        </PreloaderTracks>}

        <div className={"x_track"} ref={trackRef}>
          <PlayerMain />

          {
            Boolean(tracks.length) &&
            <div className="x_track_all_fragments"
              style={{
                display: 'flex',
                width: context.state['durationFull'] ? `${context.state['durationFull'] / zoomLevel}px` : 'auto',
                height: context.state.video !== 'full' ? '16em' : '5em'
              }}>

                <div className="x_track_hover" />

              {tracks.map((oneTrack, ind, array) => {
                // console.log('start = ', oneTrack['start']);
                // console.log('length = ', oneTrack['length']);
                let previousTrack = ind === 0 ? null : array[ind - 1];
                let nextTrack = ind === array.length - 1 ? null : array[ind + 1];
                let marginLeft = previousTrack ? oneTrack['start'] - (previousTrack['start'] + previousTrack['length']) : 0;
                let marginRight = nextTrack ? nextTrack['start'] - (oneTrack['start'] + oneTrack['length']) : oneTrack['length'];
                // if (ind < 5) console.log('`track_${oneTrack[id]}_${ind}}` = ', `track_${oneTrack['id']}_${ind}_${marginLeft}`);
                return (
                  <DraggingFragment
                    subtitles={subtitles}
                    // key={`track_${oneTrack['id']}_${ind}_${marginLeft}`}
                    key={`track_${oneTrack['id']}_${ind}`}
                    oneTrack={oneTrack}
                    ind={ind}
                    id={oneTrack['id']}

                    marginLeft={marginLeft}
                    marginRight={marginRight}

                    handlePlayNext={handlePlayNext}
                    start={oneTrack['start']}
                    trimmed_start={oneTrack['trimmed_start']}
                    length={oneTrack['length'] ? oneTrack['length'] : oneTrack['end_rus_milli'] - oneTrack['start_rus_milli']}
                    end_rus_milli={oneTrack['end_rus_milli']}
                    start_rus_milli={oneTrack['start_rus_milli']}
                    totalLength={context.state['durationFull'] / zoomLevel}
                    tracks={tracks}
                    onUpdatePosition={updatePosition}
                    onUpdateLength={updateLength}
                    timeoutRef={timeoutRef}
                    openEditor={openEditor}
                    draggingTrack={trackRef}
                  />
                )
              })}
            </div>}

          {!tracks.length && <>
            <div style={{ display: 'flex', rowGap: '1em', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '10vh' }}>
              <div className='ico ico--100'>subtitles_off</div>
              Пока нет перевода</div>
          </>}

        </div>
      </div>
    </>
  );
});
