import styled from "styled-components";

export const ScaledSvgSC = styled.svg`
  transition: transform 0.2s;
  transform: scale(0.6);
`;

export const MaterialDesignIcons = styled.div<{ fontSize?: string }>`
  font-family:
    Material Icons,
    sans-serif;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
`;
