import styled from "styled-components";

export const ButtonWrapperSC = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent);
  color: #fff;
  cursor: pointer;
  border: 1px #000;
  border-radius: 0.4em;
  min-height: 2.2em;
  padding: 0.5em 1em;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  width: 1em;
  margin: 1em auto 0 auto;
  transition:
    box-shadow 0.2s,
    border 0.2s,
    color 0.2s,
    border-color 0.2s,
    background-color 0.2s;
  position: relative;

  &:hover {
    background-color: var(--accent_hover);
    box-shadow: inset 0 0 0 2px var(--accent);
  }
`;

export const ImageWrapperSC = styled.div<{ url: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #000;
  border-radius: 0.4em;
  min-height: 2.2em;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  margin: 0.2em;
  height: calc(100% - 0.4em);
  background-image: ${({ url }) => url && `url(${url})`};
  background-size: auto;
  background-position: center;
`;
