import React from "react";
import "../../add2.css";
import "../../Reels/scrollbar-dark.css";
import { Tracking } from "./components/Tracking";
import { VideoPlayer } from "./components/VideoPlayer";
import { DebugVideo } from "./components/DebugVideo";
import {
  PlayerWrapperSC,
  VideoLayoutBackgroundSC,
  VideoLayoutMainSC,
  VideoLayoutSectionSC,
} from "../../layouts/videoFull/videoFull.styled";
import { VideosMenu } from "./components/VideosMenu";
import { VideosHead } from "./components/VideosHead";

export const Videos = React.memo(() => {
  const [playing, setPlaying] = React.useState<boolean>(false);
  return (
    <React.Fragment>
      <VideoLayoutMainSC>
        <VideoLayoutBackgroundSC>
          {/*<VideosMenu />*/}

          <VideoLayoutSectionSC>
            <VideosHead setPlaying={setPlaying} playing={playing} />

            <PlayerWrapperSC>
              <VideoPlayer playing={playing} setPlaying={setPlaying} />
            </PlayerWrapperSC>

            <Tracking setPlaying={setPlaying} />

            <DebugVideo />
          </VideoLayoutSectionSC>
        </VideoLayoutBackgroundSC>
      </VideoLayoutMainSC>
    </React.Fragment>
  );
});
