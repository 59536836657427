import React, { useEffect } from "react";
import { Button, Divider, Dropdown, Space, theme } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import {
  DropdownContainerSC,
  DropdownItemSC,
  DropdownTextWrapperSC,
  ImageItemSC,
  ItemTextSC,
  UploadButtonContainerSC,
} from "./styled";
import { downloadImage } from "utils/downloadImage";

interface IImageUploadDropdownProps {
  children: React.ReactNode;
  items: {
    key: string;
    text: string;
    value: number;
  }[];
  onItemClick: () => void;
}

const mockImg =
  "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/5PNlDRM/videoblocks-183z_000087_a022_08220040_c075_bakh41wpt_thumbnail-180_01.jpg";

export const ImageUploadDropdown: React.FC<IImageUploadDropdownProps> = ({
  children,
  onItemClick,
  items,
}) => {
  const handleDownload = (url: string, filename: string) => () => {
    downloadImage(url, filename);
    onItemClick();
  };

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={(menu) => (
        <DropdownContainerSC>
          {items.map((item) => {
            return (
              <DropdownItemSC>
                <DropdownTextWrapperSC>
                  <ItemTextSC>{item.value + ") " + item.text}</ItemTextSC>
                </DropdownTextWrapperSC>
                <ImageItemSC src={mockImg}>
                  <UploadButtonContainerSC onClick={handleDownload(mockImg, "image")}>
                    <div className="x_button x_button--icon">
                      <DownloadOutlined />
                    </div>
                  </UploadButtonContainerSC>
                </ImageItemSC>
              </DropdownItemSC>
            );
          })}
        </DropdownContainerSC>
      )}
    >
      {children}
    </Dropdown>
  );
};
