import styled from "styled-components";

export const AddButtonContainerSC = styled.div<{ show: string }>`
  z-index: 1;
  border-radius: var(--radius);
  cursor: pointer;
  justify-content: center;
  align-items: flex-start;
  padding-top: 1.3em;
  display: flex;
  position: absolute;
  inset: 0%;
  opacity: ${({ show }) => (show === "true" ? 1 : 0)};
  transition: opacity cubic-bezier(0.6, 0.04, 0.98, 0.335);
`;
