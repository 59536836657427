import styled, { css } from "styled-components";

export const DefaultStyle = css`
  display: flex;
  gap: 0.8em;
`;

export const DebugTextSC = styled.div`
  ${DefaultStyle}
`;

export const ContainerSC = styled.div`
  ${DefaultStyle};
  font-size: 0.8em;
`;
