import React, { useCallback, useState } from "react";
import uniqid from "uniqid";
import { ContainerSC, ButtonWrapperSC } from "./styled";
import { ITextData } from "providers/VideosProvider/types";
import { TextModal } from "../../../TextModal";
import { PopoverH } from "../../../../../../UiKit/PopoverH";
import { ButtonH } from "../../../../../../UiKit/ButtonH";

const initialTextData: ITextData = {
  text: "",
  type: "text",
  startTime: 0,
  endTime: 100,
  id: uniqid(),
  length: 100,
  backgroundColor: "",
  color: "fff",
  fontSize: 16,
  size: { width: 200, height: 50 },
  position: { x: 100, y: 100 },
};

interface IAddTextProps {
  setPlaying: (value: boolean) => void;
}

export const AddText: React.FC<IAddTextProps> = ({ setPlaying }) => {
  const [isTextModalOpen, setIsTextModalOpen] = useState(false);
  const [isPictureModalOpen, setIsPictureModalOpen] = useState(false);

  const showTextModal = () => {
    setIsTextModalOpen(true);
    setPlaying(false);
  };

  const showPictureModal = () => {
    setIsPictureModalOpen(true);
    setPlaying(false);
  };

  const handleCloseTextModal = useCallback(() => {
    setIsTextModalOpen(false);
  }, []);

  const handleClosePictureModal = useCallback(() => {
    setIsPictureModalOpen(false);
  }, []);

  return (
    <>
      <ContainerSC>
        <PopoverH
          key={`popover_delete_${uniqid()}`}
          title={
            <ButtonWrapperSC>
              <ButtonH onClick={() => {}}>Добавить текст</ButtonH>
              <ButtonH onClick={() => {}}>Добавить картинку</ButtonH>
            </ButtonWrapperSC>
          }
        >
          <div className={`x_track_new_button`} onClick={showTextModal}>
            <div className="t_icon t_icon--40">add</div>
          </div>
        </PopoverH>
      </ContainerSC>

      <TextModal
        isOpen={isTextModalOpen}
        closeModal={handleCloseTextModal}
        initialTextData={initialTextData}
      />
    </>
  );
};
