import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";
import { VideosContext } from "providers/VideosProvider/videosProvider";
import { usePreventPauseWithSpace } from "../../hooks/usePreventPauseWithSpace";
import { useIndexedDB } from "hooks/useIndexedDb";
import { Database } from "utils";
import { IVideoIndexDB } from "types/common";
import { IVideoData } from "../../../../../../../providers/VideosProvider/types";
import { VIDEO_PLAYERS_CONTAINER_ID } from "../../constants";

interface IPlayerProps {
  video: IVideoIndexDB | null;
  isFinished: boolean;
  videoPlaying: boolean;
  playing: boolean;
}
export const TrackPlayer: React.FC<IPlayerProps> = ({
  video,
  isFinished,
  videoPlaying,
  playing,
}) => {
  // const { state, handles } = useContext(VideosContext);
  const trackVideoRef = useRef<HTMLVideoElement>(null);
  const trackPlayerRef = useRef<Player>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  usePreventPauseWithSpace();
  useEffect(() => {
    if (!trackPlayerRef.current) {
      return;
    }
    if (playing && trackPlayerRef.current.paused()) {
      trackPlayerRef.current.play();
    }
    if (!playing && !trackPlayerRef.current.paused()) {
      trackPlayerRef.current.pause();
    }
  }, [playing]);

  // const restartVideo = () => {
  //   if (trackPlayerRef.current) {
  //     trackPlayerRef.current.currentTime(0);
  //   }
  // };

  // Initialize video.js player
  useEffect(() => {
    if (!video) {
      return;
    }
    const updateTime = (event: Event) => {
      event.stopPropagation();

      if (!trackPlayerRef.current) return;
      const currentTime = Math.round((trackPlayerRef.current.currentTime() || 0) * 1000);
      setCurrentTime(currentTime); // Current time in milliseconds
    };

    if (trackVideoRef.current && !trackPlayerRef.current) {
      trackPlayerRef.current = videojs(trackVideoRef.current, {
        controls: true,
        autoplay: false,
        preload: "auto",
      });
      trackPlayerRef.current.on("loadedmetadata", (event: Event) => {
        event.stopPropagation();
        if (trackPlayerRef.current) {
          const duration = Math.round((trackPlayerRef.current?.duration() || 0) * 1000);
          setVideoDuration(duration); // Duration in milliseconds
        }
      });

      trackPlayerRef.current.on("timeupdate", updateTime);
      trackPlayerRef.current.on("canplay", (event: Event) => {
        event.stopPropagation();
        if (videoPlaying) {
          trackPlayerRef.current?.play();
        }
        if (!videoPlaying) {
          trackPlayerRef.current?.pause();
        }
      });
    } else if (trackPlayerRef.current && videoPlaying) {
      trackPlayerRef.current.play();
    }
  }, [video]);

  useEffect(() => {
    if (isFinished && trackVideoRef.current) {
      if (trackVideoRef.current.parentElement?.id !== VIDEO_PLAYERS_CONTAINER_ID) {
        trackVideoRef.current.parentElement?.classList.add("hiddenElement");
      }
    } else if (trackVideoRef.current) {
      trackVideoRef.current.parentElement?.classList.remove("hiddenElement");
    }
  }, [isFinished]);

  // Restart video if context.currentTime is reset to 0
  // useEffect(() => {
  //   if (state.currentTime === 0) {
  //     restartVideo();
  //   }
  // }, [state.currentTime]);

  const videoUrl = useMemo(() => {
    if (video) {
      return URL.createObjectURL(new Blob([video?.content || 0], { type: video?.type }));
    }
  }, [video]);

  return (
    <video
      ref={trackVideoRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: video ? "100%" : "0",
        zIndex: 5,
        backgroundColor: "#000",
      }}
      src={videoUrl}
      className={"track-player"}
    >
      <source src={videoUrl} type={video?.type} />
      Your browser does not support the video tag.
    </video>
  );
};
