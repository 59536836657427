import React, { useEffect, useState } from 'react'
import example_data from './audio/example_data.json';
import mp from './audio/torsunov.mp3';
import { prepareDataOne, checkTrimmed, Database, getPrevTrackId, postData } from './utils';
import { useIndexedDB } from './hooks/useIndexedDb';

const API_URL = process.env.REACT_APP_API_URL;


const Context = React.createContext();

const debug = false;
const offline = false;


function Provider({ children }) {


  //TODO: example data
  const [mp3, setMp3] = useState(offline ? mp : null);
  const [mediaId, setMediaId] = useState(offline ? 'offline' : null);
  const [translationDataAll, setTranslationDataAll] = useState(offline ? [...example_data] : []);


  // const [mp3, setMp3] = useState(null);
  // const [mediaId, setMediaId] = useState();
  // const [translationDataAll, setTranslationDataAll] = useState([]);



  const [zoomFragment, setZoomFragment] = useState([0, 100])
  const [scrubberWidth, setScrubberWidth] = useState(10000)
  const [playStart, setPlayStart] = useState(10000)
  const [playOriginalStart, setPlayOriginalStart] = useState(10000)



  const [audioDuration, setAudioDuration] = useState(0)
  const [durationFull, setDurationFull] = useState(0)

  const [playing, setPlaying] = useState(false);
  const [playbackTime, setPlaybackTime] = useState(0);
  const [activeSegmentLength, setActiveSegmentLength] = useState(10);
  //The length of the active segment in PERCENTAGE of the total audio length

  const [name, setName] = useState('');
  const [lang, setLang] = useState('');
  const [folder, setFolder] = useState(1);
  const [foldersTotal, setFoldersTotal] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(Number(localStorage.getItem('playbackRate') ?? 1));

  const [isDragging, setIsDragging] = useState(false);


  //TODO: при изменении генерировать массив и передавать на сервер
  const [voicingSpaces, setVoicingSpaces] = useState([]);
  const [voicingSpacesDeleted, setVoicingSpacesDeleted] = useState([]);
  //TODO: при включении основной плеер при проигрывании должен перепрыгивать промежутки
  const [voicingSpacesOn, setVoicingSpacesOn] = useState(false);


  const [voicingSpacesMinimum, setVoicingSpacesMinimum] = useState(localStorage.getItem('voicingSpacesMinimum') ?? 1000);
  const [voicingSpacesMinimumOffset, setVoicingSpacesMinimumOffset] = useState(localStorage.getItem('voicingSpacesMinimumOffset') ?? 500);


  const [translationData, setTranslationData] = useState([]);

  const [playingFragment, setPlayingFragment] = useState();
  const [translateLineOffset, setTranslateLineOffset] = useState(0);

  const [volumeOriginal, setVolumeOriginal] = useState(40);
  const [volumeTranslated, setVolumeTranslated] = useState(60);

  const [zoomLevel, setZoomLevelState] = useState(() => {
    const savedZoomLevel = localStorage.getItem('zoomLevel');
    return savedZoomLevel ? JSON.parse(savedZoomLevel) : 80; // Default to 80 if not found
  });

  const [newData, setNewData] = useState([]);

  const [changedRecently, setChangedRecently] = useState([]);
  const [changed, setChanged] = useState([]);
  const [changedTranslation, setChangedTranslation] = useState([]);
  const [changedRus, setChangedRus] = useState([]);
  const [changedReels, setChangedReels] = useState([]);

  const [changeToSave, setChangeToSave] = useState();


  const [pop, setPopup] = useState()


  const [scrollTo, setScrollTo] = useState()

  const [video, setVideo] = useState()
  const [russian, setRussian] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')


  const [savingStatus, setSavingStatus] = useState(false)

  const {
    putValue,
    getValue,
    deleteValue,
    isDBConnecting,
  } = useIndexedDB(Database.name, [Database.audioTable]);

  const setZoomLevel = (newZoomLevel) => {
    setZoomLevelState(newZoomLevel);
    localStorage.setItem('zoomLevel', JSON.stringify(newZoomLevel));
  };

  const getPlayerFromLocal = () => {
    let d
    d = localStorage.getItem('playerType');
    // return d ? d : 'advanced';
    return d ? d : 'simple';
  };

  const [videoPlayerType, setVideoPlayerType2] = useState(getPlayerFromLocal())

  const setVideoPlayerType = (typ) => {
    localStorage.setItem('playerType', typ);
    setVideoPlayerType2(typ)
  }

  const [debugFullFunctionality, setDebugFullFunctionality] = useState(true)
  const [lastFragmentTrimmed, setLastFragmentTrimmed] = useState(false)
  const [subtitleMode, setSubtitleMode] = useState(false)

  const setPop = (id) => {
    if (!id) {
      setChangedRecently([pop])
      setPopup()
      setTimeout(() => {
        setChangedRecently([])
      }, 2000);
    } else {
      setPopup(id)
      // scrollToElement(id)
      setChangedRecently([id])
      setTimeout(() => {
        setChangedRecently([])
      }, 2000);
    }
  }




  const saveToLocal = () => {
    localStorage.setItem(mediaId, JSON.stringify({
      'play': playingFragment,
      'changed': changed,
      'changedTranslation': changedTranslation,
      'changedRus': changedRus,
      'changedReels': changedReels,
    }));
  };


  const tryParse = (string) => {
    try {
      string = JSON.parse(string)
      return string
    } catch (error) {
      console.log('wrong format');
      return false
    }
  }



  const getFromLocal = (mediaId, what) => {
    if (!mediaId) return []
    let d
    d = localStorage.getItem(mediaId);
    d = Boolean(d) ? tryParse(d) : {
      'play': '',
      'changed': [],
      'changedTranslation': [],
      'changedRus': [],
      'changedReels': [],
    }
    if (what === 'changed') {
      d = (Boolean(d) && d['changed']) ? d['changed'] : [];
      return d;
    }
    if (what === 'changedTranslation') {
      d = (Boolean(d) && d['changedTranslation']) ? d['changedTranslation'] : [];
      return d;
    }
    if (what === 'changedRus') {
      d = (Boolean(d) && d['changedRus']) ? d['changedRus'] : [];
      return d;
    }
    if (what === 'changedReels') {
      d = (Boolean(d) && d['changedReels']) ? d['changedReels'] : [];
      return d;
    }
    if (what === 'play') {
      d = Boolean(d) ? d['play'] : '';
      return d;
    }
  };



  // useEffect(() => {
  //   newData.length && saveMoving()
  // }, [newData])

  const prepareData = (allData, folder_num) => {
    const folder = parseInt(folder_num) - 1;
    let translationsArray = allData[folder]['translations'] || []
    if (!translationsArray.length) return []
    translationsArray = translationsArray
      .map((one, ind, arr) => prepareDataOne(one, folder_num, allData))
      .sort((a, b) => a.start - b.start)
    console.log('translationsArray 1 = ', translationsArray);
    // translationsArray = translationsArray.filter(one => one['folder_num'] === folder_num)
    // console.log('translationsArray 2 = ', translationsArray);
    let lastFragmentEnd = translationsArray[translationsArray.length - 1]['start'] + translationsArray[translationsArray.length - 1]['length'];
    if (lastFragmentEnd > audioDuration) {
      setDurationFull(lastFragmentEnd)
    }

    // setLang(translationsArray[0].lang)

    return translationsArray
  }


  const jsonPreview = (jsonString) => {
    const newWindow = window.open();
    newWindow.document.write(`<pre>${jsonString}</pre>`)
  };


  const saveMoving = async (newData, doing = 'move') => {
    console.log('doing = ', doing);

    const api = {
      "move": 'move_tts',
      "length": 'change_len_tts',
      "move_rus": 'metadata_edit_subtitle',
      "length_rus": 'metadata_edit_subtitle',
    };

    let addLength = parseInt(translationDataAll[parseInt(folder) - 1]['prev_mp3_length_aggr']) || 0;

    if (!changeToSave) return;

    saveToLocal();
    console.log('newData[0] = ', newData[0]);

    let jsonData = newData.find(
      one => typeof changeToSave === "string" ?
        one['id'] === changeToSave :
        one['id'] === changeToSave.id
    ) || {};
    let newJsonData = {
      id: jsonData['original_id'],
      folder_num: jsonData['folder_num'],
      lang: jsonData['lang'],
    };

    if (doing === 'move') {
      newJsonData['shifted_start'] = jsonData['original_shifted_start'];
      newJsonData['new_shifted_start'] = jsonData['start'];
      newJsonData['start'] = jsonData['original_start'];
      newJsonData['new_start'] = addLength + jsonData['start'];
    }

    if (doing === 'move_rus') {
      newJsonData['start_rus_milli'] = addLength + jsonData['start_rus_milli'];
      newJsonData['end_rus_milli'] = addLength + jsonData['end_rus_milli'];
    }

    if (doing === 'length') {
      newJsonData['captions'] = jsonData['captions'];
      newJsonData['voice'] = jsonData['voice'];
      newJsonData['speed'] = jsonData['speed'];
      newJsonData['add_backround'] = jsonData['add_backround'];
      newJsonData['new_end_rus_milli'] = addLength + jsonData['end_rus_milli'];
    }

    if (doing === 'length_rus') {
      newJsonData['end_rus_milli'] = addLength + jsonData['end_rus_milli'];
    }

    const jsonString = JSON.stringify(newJsonData, null, 2);

    if (doing === 'move') {
      console.log('Sending this (moving ⏭) = ', jsonString);
    }
    if (doing === 'move_rus') {
      console.log('Sending this (moving RUS ⏭) = ', jsonString);
    }
    if (doing === 'length') {
      console.log('Sending this (length ⏭) = ', jsonString);
    }
    if (doing === 'length_rus') {
      console.log('Sending this (length RUS ⏭) = ', jsonString);
    }

    setSavingStatus(true);

    if (jsonString) {
      try {
        const data = await postData(`${API_URL}/${api[doing]}?media=${mediaId}`, jsonString);
        console.log('Server: ', data); // JSON data parsed by `response.json()` call
        await deleteValue(Database.audioTable, `${API_URL}/${jsonData.file}`);
        setChangeToSave();
        setSavingStatus(false);
        return data;
      } catch (error) {
        console.error('Error in saveMoving:', error);
        setSavingStatus(false);
        throw error;
      }
    }
  };


  const getNextTrackId = (currentTrackId, tracks = translationData) => {
    const currentIndex = tracks.findIndex(track => track.id === currentTrackId);
    if (currentIndex === tracks.length - 1 || currentIndex === -1) {
      return null
    } else {
      return tracks[currentIndex + 1].id;
    }
  };
  

  const deleteFragment = (oneTrack) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this fragment?");
    if (isConfirmed) {
      let jsonData = {
        id: oneTrack['original_id'],
        // file: oneTrack['file'],
        folder_num: oneTrack['folder_num'],
        lang: oneTrack['lang']
        //lang: lang
      };
      const jsonString = JSON.stringify(jsonData, null, 2);
      // jsonPreview(jsonString)
      console.log('Sending this (deleting 🔴) = ', jsonString);
      setChanged(prev => prev.filter(one => one !== oneTrack['id']));
      setSavingStatus(true);
      jsonString && postData(`${API_URL}/delete_tts?media=${mediaId}`, jsonString)
        .then(data => {
          setSavingStatus(false)
          console.log('Server: ', data); // JSON data parsed by `response.json()` call
          fetchTracks(mediaId, () => {
            setPop();
            scrollToElement(getPrevTrackId(oneTrack['id'], translationData));
          })
        });
    } else {
      // Do nothing if not confirmed
      console.log('Deletion cancelled.');
    }
  };

  const [waitingCut, setWaitingCut] = useState([])

  useEffect(() => {
    // console.log('changed = ', changed);
    saveToLocal()
  }, [changed, changedTranslation, changedRus])

  useEffect(() => {
    checkTrimmed(newData, false, translationDataAll, folder)
  }, [folder])

  const fetchMp3 = (mediaId, folder) => {
    setMp3(null)
    fetch(`${API_URL}/download_rus?file_name=${mediaId}/files_to_send/${folder}.mp3`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.blob();
      })
      .then(blob => {
        console.log('MP3 = ', blob);
        const url = window.URL.createObjectURL(blob);
        setMp3(url);  // Store the object URL
      })
      .catch(error => {
        console.error("Error fetching MP3: ", error);
      });
  }    

  const fetchMp3Cached = async (mediaId, folder, putValue, getValue) => {
    setMp3(null);
    try {
      const blob = await getMp3(mediaId, folder, putValue, getValue);
      
      console.log('MP3 = ', blob);
      const url = window.URL.createObjectURL(blob);
      setMp3(url);
      
    } catch(error) {
      console.error("Error fetching MP3: ", error);
    };
  }

  const getMp3 = async (mediaId, folder, putValue, getValue) => {
    const blobFromInexedDB = await getValue(Database.audioTable, `${mediaId}-${folder}`);
    if (blobFromInexedDB) {
      return blobFromInexedDB.content;
    }
    const response = await fetch(`${API_URL}/download_rus?file_name=${mediaId}/files_to_send/${folder}.mp3`);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const blob = await response.blob();
    await putValue(
      Database.audioTable,
      { mediaId,
        folder,
        content: blob,
        id: `${mediaId}-${folder}`,
      }
    );
    return blob;
  }

  useEffect(() => {
    const audio = new Audio(mp3);
    audio.onloadedmetadata = () => {
      setAudioDuration(audio.duration * 1000); // duration is in seconds, so multiply by 1000 to get milliseconds
      setDurationFull(audio.duration * 1000); // duration is in seconds, so multiply by 1000 to get milliseconds
    };
    return () => {
      if (mp3) {
        window.URL.revokeObjectURL(mp3);
      }
    };
  }, [mp3]);

  useEffect(() => {
    if (playingFragment) {
      saveToLocal()
    }
  }, [playingFragment]);



  const fetchTracks = (mediaId, callback) => {
    setTranslationDataAll([])
    fetch(`${API_URL}/get_all_data_for_editing?media=${mediaId}${lang ? `&lang=${lang}` : ''}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          debug && console.log('data = ', JSON.stringify(data));
          setTranslationDataAll(data);
          // console.log('Translation = ', data);
          if (typeof callback === 'function') callback()
        }
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }


  const fetchFolders = (mediaId) => {
    setFoldersTotal(1)
    fetch(`${API_URL}/cnt_fls?media=${mediaId}${lang ? `&lang=${lang}` : ''}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setFoldersTotal(data['numPages']);
      })
      .catch(error => {
        console.error("Error fetching folders: ", error);
      });
  }

  const fetchVideoUrl = (mediaId) => {
    setVideoUrl('')
    fetch(`${API_URL}/get_filename_from_media?media=${mediaId}${lang ? `&lang=${lang}` : ''}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(data => {
        console.log('VIDEO = ', data);
        setVideoUrl(data);
        setVideo('sm')
      })
      .catch(error => {
        console.error("Error fetching video: ", error);
      });
  }

  const playToggle = () => {
    if (playing) {
      setPlayingFragment()
    }
    setPlaying(!playing)
  }




  useEffect(() => {
    if (offline) return
    if (isDBConnecting) return;
    console.log('folder = ', folder);
    if (mediaId) {
      setAudioDuration(0)
      setDurationFull(0)
      setPlaybackTime(0)
      setPlaying(false)
      fetchFolders(mediaId)
      fetchMp3Cached(mediaId, folder, putValue, getValue)
      fetchTracks(mediaId)
      setPlayingFragment()
      video && fetchVideoUrl(mediaId)
    }
    if (mediaId) {
      setChanged(getFromLocal(mediaId, 'changed'), 'changed')
      setChangedTranslation(getFromLocal(mediaId, 'changedTranslation'), 'changedTranslation')
      setChangedRus(getFromLocal(mediaId, 'changedRus'), 'changedRus')
    }
  }, [mediaId, isDBConnecting])


  useEffect(() => {
    if (isDBConnecting) return;
    if (translationDataAll.length && mediaId && folder) {
      fetchMp3Cached(mediaId, folder, putValue, getValue)
    }
  }, [folder, isDBConnecting])

  /*useEffect(() => {
    if (!isDBConnecting && translationDataAll.length && mediaId && folder) {
      fetchMp3(mediaId, folder)
    }
  }, [mediaId, folder, isDBConnecting, translationDataAll])*/

  useEffect(() => {
    if (translationDataAll.length && mediaId && folder) {
      // fetchMp3(mediaId, folder)
      console.log('setTranslationData = ');
      setTranslationData(prepareData(translationDataAll, folder))
      console.log('setTranslationData 🔴🌕🔴🌕🔴');
      video && fetchVideoUrl(mediaId)
    }
  }, [mediaId, translationDataAll, folder])

  useEffect(() => {
    if (video && !videoUrl && mediaId) { fetchVideoUrl(mediaId) }
  }, [video, mediaId])

  const getElementById = (id) => {
    if (!translationData.length) return {};
    let el;
    if (translationData.filter(one => one['id'] === id).length) {
      el = translationData.filter(one => one['id'] === id)[0]
    }
    return el ? el : {}
  }


  useEffect(() => {
    let id = getFromLocal(mediaId, 'play');
    if (id) {
      // scrollToElement(id)
    }
  }, [translationData])


  const scrollToElement = (id) => {
    if (!id) setScrollTo()
    if (translationData.length) {
      setScrollTo(getElementById(id)['start'] / (100 - zoomLevel) - 200)
      setTimeout(() => {
        setChangedRecently([id])
      }, 700);
    }
  }

  const updatePosition = async (id, newPosition, tracks, subtitles) => {
    console.log('newPosition = ', newPosition);
    let prevTracks = JSON.parse(JSON.stringify(tracks));
    let updatedTrack = prevTracks.find(one => one['id'] === id);

    if (!subtitles) {
      updatedTrack['start'] = newPosition;
    } else {
      updatedTrack['start_rus_milli'] = newPosition;
    }

    prevTracks = prevTracks.map(track =>
      track.id === id ? { ...updatedTrack } : track
    );

    let newTracks = checkTrimmed(prevTracks, false, translationDataAll, folder);

    await saveMoving(newTracks, subtitles ? 'move_rus' : 'move');
    setNewData(newTracks);
    setTranslationData(newTracks);
  };

  const updateLength = async (id, newLength, tracks, subtitles) => {
    console.log('newLength = ', newLength);
    let prevTracks = JSON.parse(JSON.stringify(tracks));
    let updatedTrack = prevTracks.find(one => one['id'] === id);

    updatedTrack['end_rus_milli'] = newLength + updatedTrack['start_rus_milli'];
    if (!subtitles) {
      updatedTrack['length'] = newLength;
    }

    let newTracks = checkTrimmed(
      prevTracks.map(track =>
        track.id === id ? { ...updatedTrack } : track
      ),
      true,
      translationDataAll,
      folder,
    );

    await saveMoving(newTracks, subtitles ? 'length_rus' : 'length');
    setNewData(newTracks); // TODO dataNew is not used
    setTranslationData(newTracks);
  };


  function timeStringToMs(timeString) {
    // Split the time string by colon and period
    const parts = timeString.split(':');
    const secondsParts = parts[2].split('.');

    // Extract hours, minutes, seconds, and milliseconds
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(secondsParts[0], 10);
    const milliseconds = parseInt(secondsParts[1], 10);

    // Calculate total milliseconds
    return (hours * 3600000) + (minutes * 60000) + (seconds * 1000) + milliseconds;
  }

  const audioSynthesize = async (trackId, folder, mediaId) => {
    try {
      const response = await fetch(`${API_URL}/metadata_synthesize?media=${mediaId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: [trackId],
          folder_num: folder,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let data = await response.json();
      //data = data.map(one => prepareDataOne(one, folder, translationDataAll));
      console.log('Audio synthesis response:', data);
      fetchTracks(mediaId, () => {

      })

      return data;
    } catch (error) {
      console.error('Error synthesizing audio:', error);
      throw error;
    }
  };






  return (<>
    <Context.Provider
      value={{
        debug: {
          debugFullFunctionality,
        },
        state: {
          isDragging,
          zoomFragment,
          audioDuration,
          playing,
          playbackTime,
          activeSegmentLength,
          mediaId,
          mp3,
          translationData,
          translationDataAll,
          name,
          lang,
          video,
          playingFragment,
          volumeOriginal,
          volumeTranslated,
          zoomLevel,
          changedRecently,
          changed,
          changedTranslation,
          changedRus,
          newData,
          scrubberWidth,
          playStart,
          playOriginalStart,
          translateLineOffset,
          folder,
          foldersTotal,
          playbackRate,
          waitingCut,
          pop,
          scrollTo,
          videoUrl,
          lastFragmentTrimmed,
          subtitleMode,
          videoPlayerType,
          savingStatus,
          russian,
          durationFull,
          voicingSpaces,
          voicingSpacesDeleted,
          voicingSpacesMinimum,
          voicingSpacesMinimumOffset,
          voicingSpacesOn,
        },
        handles: {
          setIsDragging,
          getNextTrackId,
          setZoomFragment,
          setAudioDuration,
          setPlaying,
          setPlaybackTime,
          setActiveSegmentLength,
          setMediaId,
          setMp3,
          setTranslationData,
          setName,
          setLang,
          setVideo,
          setPlayingFragment,
          setTranslateLineOffset,
          playToggle,
          setVolumeOriginal,
          setVolumeTranslated,
          setZoomLevel,
          setNewData,
          setVoicingSpaces,
          setVoicingSpacesDeleted,
          setChangeToSave,
          setWaitingCut,
          setScrubberWidth,
          setPlayStart,
          setPlayOriginalStart,
          setFolder,
          setPlaybackRate,
          saveMoving,
          setPop,
          setChangedRecently,
          setScrollTo,
          scrollToElement,
          setDebugFullFunctionality,
          getElementById,
          deleteFragment,
          setSubtitleMode,
          updatePosition,
          updateLength,
          setVideoPlayerType,
          setRussian,
          setVoicingSpacesMinimumOffset,
          setSavingStatus,
          audioSynthesize,
          setVoicingSpacesMinimum,
          setVoicingSpacesOn,
          setChangedTranslation,
          setChanged,
          setChangedRus,
          setChangedReels,
          fetchTracks,
        },
      }}
    >
      {children}
    </Context.Provider>
  </>
  )

}

const Consumer = Context.Consumer;

export { Context, Provider, Consumer };
