import React from 'react';
import './range.css';

interface HorizontalSliderProps {
  value?: number;
  onValueChange?: (value: number) => void;
  width?: number;
  min?:  number;
  max?: number;
  step?: number;
}

export const HorizontalSlider = (
  { value = 0, onValueChange, width = 80, min = 0, max = 99, step = 1 }: HorizontalSliderProps
) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return (
    <input
      type="range"
      min={min}
      max={max}
      value={value}
      step={step}
      onChange={handleChange}
      style={{ width }}
    />
  );
};
