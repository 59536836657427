import styled, { css } from "styled-components";

export const InactiveCSS = css`
  pointer-events: none;
`;

export const TrackStartSC = styled.div`
  z-index: 100;
  background-color: var(--accent);
  opacity: 1;
  width: 2px;
  transition: opacity 0.2s;
  position: absolute;
  inset: -11em auto -200px 0;
`;

export const TrackHoverSC = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isVideo" && prop !== "top",
})<{ isVideo?: boolean; top?: string }>`
  background-color: var(--gray);
  opacity: 0.5;
  mix-blend-mode: multiply;
  border-radius: 0.4em;
  height: 4em;
  transition: opacity 0.2s;
  position: absolute;
  inset: 0 0 auto;
  top: ${({ top }) => top || 0};
  box-shadow:
    0 1px #ffffff96,
    inset 0 3px 8px #0000,
    inset 0 3px 11px -6px #000000b8;

  ${({ isVideo }) =>
    isVideo &&
    css`
      background-color: var(--black);
      box-shadow:
        0 1px #5a595996,
        inset 0 3px 8px #000,
        inset 0 3px 11px -6px #000000b8;
    `}
`;

export const VideoLayoutMainSC = styled.div`
  height: 100vh;
  flex-direction: column;
  display: flex;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  font-size: 18px;

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const VideoLayoutBackgroundSC = styled.div`
  background-color: var(--black);
  color: var(--reels-txt);
  height: 100%;
  max-height: 100vh;
  display: flex;
`;

export const VideoLayoutSectionSC = styled.div`
  flex-flow: column;
  flex: 0 auto;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const PlayerWrapperSC = styled.div`
  background-color: #000;
  flex: 1;
  position: relative;
`;
