import styled from "styled-components";

export const ContainerSC = styled.div`
  justify-content: center;
  align-items: center;
  width: 4em;
  height: 4em;
  display: flex;
  position: absolute;
  inset: auto 0 100%;
`;

export const ButtonWrapperSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
