import React, { forwardRef } from "react";
import { TrackDragIcon } from "../icons/TrackDragIcon";
import { TrackDragHandleSC, TrackDragIndicatorSC } from "./styled";
interface TrackDragIndicatorProps {}

export const TrackDragIndicator = forwardRef<HTMLDivElement, TrackDragIndicatorProps>(({}, ref) => {
  return (
    <TrackDragIndicatorSC ref={ref}>
      <TrackDragHandleSC>
        <TrackDragIcon />
      </TrackDragHandleSC>
    </TrackDragIndicatorSC>
  );
});
