import React, { useMemo } from "react";

export function TimeLine({ videoDuration, zoomLevel, style = {} }) {
  const { tickElements, tickStyle } = useMemo(() => {
    const pixelsPerTick = 50;
    const secondsPerTick = Math.max(
      1,
      Math.floor(videoDuration / zoomLevel / pixelsPerTick / 1000),
    );
    const totalTicks = Math.floor((videoDuration / 1000 / secondsPerTick) * zoomLevel);

    const tickElements = Array.from({ length: totalTicks }, (_, index) => (
      <div key={index} className="x_time_tick" />
    ));
    const tickStyle = {
      // width: `${100 / totalTicks}%`,
    };

    return { tickElements, tickStyle };
  }, [videoDuration, zoomLevel]);

  return (
    <div className="x_time0 inact" style={{ width: videoDuration / zoomLevel, ...style }}>
      {tickElements.map((tick, index) => (
        <div
          key={index}
          className={zoomLevel > 20 ? "x_time_tick toodense" : "x_time_tick"}
          style={tickStyle}
        />
      ))}
    </div>
  );
}
