import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Context } from "../context";
import ReactWaves from '@dschoon/react-waves';

const API_URL = process.env.REACT_APP_API_URL;

export const PlayerLine = ({ track }) => {
    const context = useContext(Context);
    const wavesRef = useRef(null);
  
    const [wavesurfer, setWavesurfer] = useState(null);
    const [pos, setPos] = useState(0);
  
    const options = {
      barHeight: 2,
      barRadius: 3,
      cursorWidth: 0,
      height: 60,
      hideScrollbar: true,
      progressColor: '#ff7606',
      responsive: true,
      waveColor: '#D1D6DA',
      interact: false,
      dragSelection: false
    };
  
    const onLoading = ({ wavesurfer }) => {
      setWavesurfer(wavesurfer);
    };
  
    const onPosChange = useCallback((newPos, wavesurfer) => {
      if (newPos !== pos) {
        setPos(newPos);
        setWavesurfer(wavesurfer);
      }
    }, [pos]);
  
    const handleResetAndToggle = () => {
      if (typeof context.state.playStart === 'number' && context.state.playingFragment === track.id) {
        wavesurfer.seekTo(0);
      }
    };
  
    useEffect(() => {
      setPos(0);
  
      if (wavesurfer && typeof context.state.playStart === 'number') {
        wavesurfer.seekTo(0);
      }
  
      return () => {
        // Cleanup if needed
      };
    }, [context.state['playingFragment'], context.state.playStart, track.id, wavesurfer]);
  
    const [audioUrl, setAudioUrl] = useState(`${API_URL}/${track.file}?cache=${Date.now()}`);
  
  
    const recacheAudio = useCallback(() => {
      setAudioUrl(`${track.file}?cache=${Date.now()}`);
    }, [track.file]);
  
    useEffect(() => {
      recacheAudio();
    }, [track.end_rus_milli, recacheAudio]);
  
    return (
      <div
        style={{
          paddingTop: '0.26em',
          width: '100%',
          position: 'absolute',
        }}
        onClick={handleResetAndToggle}
      >
        <div style={{
          top: 0, bottom: 0, left: 0, right: 0, zIndex: 0, position: 'absolute'
        }} />
  
        <ReactWaves
          key={audioUrl}
          ref={wavesRef}
          audioFile={audioUrl}
          className={'react-waves'}
          options={{ ...options }}
          volume={context.state['volumeTranslated'] / 100}
          zoom={(100 - context.state.zoomLevel) / 100}
          pos={pos}
          playing={context.state['playingFragment'] === track.id}
          onLoading={onLoading}
        />
      </div>
    );
  };