import styled from "styled-components";
import { InactiveCSS } from "../../../../layouts/videoFull/videoFull.styled";

export const TracksContainerCS = styled.div`
  display: flex;
`;

export const TrackAllOrder0SC = styled.div`
  grid-column-gap: 0.2em;
  grid-row-gap: 0.2em;
  background-color: var(--blue-dark);
  flex-flow: column;
  display: flex;
  left: 0;
  right: 0;
  position: sticky;
  top: 1em;
  z-index: 2;
`;

export const ZoomWrapperSC = styled.div`
  right: 1em;
  position: absolute;
  top: 1em;
  z-index: 1;
`;

export const TrackContainerSC = styled.div`
  z-index: 0;
  grid-column-gap: 0.2em;
  grid-row-gap: 0.2em;
  flex-direction: column;
  flex: 0 auto;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
  background-color: var(--blue-dark);
  overflow: auto;
`;

export const TrackPlayBackSC = styled.div<{ width: string }>`
  background-color: var(--accent);
  transition: width 0.1s linear;
  position: absolute;
  inset: 0 auto 0 0;
  opacity: 0.2;
  width: ${({ width }) => width};
  mix-blend-mode: multiply;
  left: 4em;
  z-index: 100;
  ${InactiveCSS};

  @media screen and (max-width: 479px) {
    min-width: 1em;
  }
`;

export const TrackAllFragmentsSC = styled.div<{ width: string }>`
  min-width: 80em;
  height: auto;
  position: relative;
  display: flex;
  width: ${({ width }) => width};
`;

export const TracksSectionSC = styled.div`
  z-index: 1;
  flex-direction: column;
  flex: 1 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 17em;
  display: flex;
  position: relative;
  overflow: auto;
  padding-top: 3em;
  border-bottom: 7px solid #000000;

  &::-webkit-scrollbar-track-piece {
    background: rgb(0, 0, 0);
    box-shadow: inset 0 -2px 4px #000000;
    padding: 0.2em;
    height: 1.6em;
    cursor: ew-resize;
  }
`;
