import React, { forwardRef } from "react";
import { DragIndicatorMIcon } from "../icons/DragIndicatorMIcon";
import { TrackDragIcon } from "../icons/TrackDragIcon";
import { LengthIndicatorSC, TrackLengthIndicatorWrapperSC } from "./styled";

export interface TrackDragIndicatorProps {
  isLengthing: boolean;
  isStart?: boolean;
  handleLengthMouseDown?: (e: React.MouseEvent) => void;
}

export const TrackLengthChangeIndicator = forwardRef<HTMLDivElement, TrackDragIndicatorProps>(
  ({ isLengthing, handleLengthMouseDown, isStart }, ref) => {
    return (
      <TrackLengthIndicatorWrapperSC lengthing={isLengthing.toString()} start={isStart?.toString()}>
        <LengthIndicatorSC
          start={isStart?.toString()}
          ref={ref}
          onMouseDown={handleLengthMouseDown}
        >
          {!isLengthing && <DragIndicatorMIcon />}
          {isLengthing && <TrackDragIcon />}
        </LengthIndicatorSC>
      </TrackLengthIndicatorWrapperSC>
    );
  },
);
