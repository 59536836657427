import styled from "styled-components";

export const TrackLineSC = styled.div<{ top: string }>`
  border-radius: 0.4em;
  height: 4em;
  transition: opacity 0.2s;
  position: absolute;
  inset: 0 0 auto;
  top: ${({ top }) => top || 0};
`;
