import { useEffect } from "react";

export const usePreventPauseWithSpace = () => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.code === "Space" &&
        (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement)
      ) {
        event.stopPropagation();
      }
    };

    document.addEventListener("keydown", handleKeyDown, true);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  }, []);
};
