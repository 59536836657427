import React, { useState, useContext } from "react";
import { TooltipH } from "../UiKit/TooltipH"
import { PlayerVideo } from '../PlayerVideo';
import { Context } from '../../contextSubtitles';
import { Rnd } from "react-rnd";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "solid 1px #ddd",
  background: "#f0f0f0",
  zIndex: 1000
} as const;

export function SubtitlesPlayer() {
  const context = useContext(Context);
  //const [left, setLeft] = useState(true);
  const left = true;
  const expanded = context.state.video;

  if (!expanded) {
    return null;
  }

  return (
    <Rnd
      className="x_sub_player0_container"
      style={style}
      default={{
        x: 50,
        y: 200,
        width: 400,
        height: 250,
      }}
    >
      
      <div className={`x_sub_player0 ${expanded ? '' : 'x_sub_player0--collapsed'} ${left ? '' : 'x_sub_player0--right'}`}>
        <div className="x_sub_player_h">
          <div className="p_b_title">
            <div className="ico">
              <div>videocam</div>
            </div>
            <div>Видео</div>
          </div>
          <div className="x_sub_player_right">

            {/*expanded && <TooltipH title={`Переместить ${left ? 'вправо' : 'влево'}`}>
              <div className="x_button x_button--ghost"
                onClick={() => setLeft(prev => !prev)}
              >
                <div className="t_icon">
                  {left ? 'arrow_forward' : 'arrow_back'}
                </div>
              </div>
            </TooltipH>*/}

            {expanded && <div className="x_button x_button--ghost"
              onClick={() => context.handles.setVideo()}
            >
              <div>Скрыть</div>
            </div>}

            {!expanded && <div className="x_button x_button--ghost"
              onClick={() => context.handles.setVideo("sm")}
            >
              <div>Развернуть</div>
            </div>}


          </div>
        </div>
        {expanded && <div className="x_sub_player _fv _fcc">
          <PlayerVideo context={context}><></>
          </PlayerVideo>
        </div>}
      </div>
    </Rnd>
  )
}
