import React from "react";
import styled, { css } from "styled-components";
import { preloader } from "./svg_preloader";
import { Svg } from './Uikit_svg';

// Define interface for Svg component props based on the error message
interface SvgProps {
  name?: string;
  width?: number;
  height?: number;
  forceHeight: any;
  color: any;
  png: any;
  children: any;
  style?: any;
}

// Define types for styled components props
interface OverlayProps {}

interface FlexProps {
  gap?: string;
  center?: boolean;
  left?: boolean;
  right?: boolean;
  top?: boolean;
  vertical?: boolean;
}

interface ButtonProps {
  flat?: boolean;
  ghost?: boolean;
  sm?: boolean;
  disabled?: boolean;
  black?: boolean;
  round?: boolean;
  left?: boolean;
  secondary?: boolean;
}

interface MainLayoutProps {
  middle?: boolean;
}

interface LineProps {
  xs?: boolean;
  sm?: boolean;
  lg?: boolean;
  xlg?: boolean;
}

interface TextProps {
  xs?: boolean;
  sm?: boolean;
  lg?: boolean;
  xlg?: boolean;
  xxlg?: boolean;
  opacity?: number;
  bold?: boolean;
  color?: string;
  center?: boolean;
}

interface MIconProps {
  size?: number;
  left?: boolean;
  right?: boolean;
  color?: string;
}

interface PreloaderProps {
  height?: string;
}

interface IcoProps {
  size?: string;
}

export const Overlay = styled.div<OverlayProps>`
  top: 0; 
  bottom: 0; 
  left: 0; 
  right: 0;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Flex = styled.div<FlexProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ gap }) => (gap ? gap : "0.4em")};

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${({ left }) =>
    left &&
    css`
      justify-content: flex-start;
    `}
  ${({ right }) =>
    right &&
    css`
      justify-content: flex-end;
    `}
  ${({ top }) =>
    top &&
    css`
      align-items: flex-start;
    `}
  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column;
    `}

  @media (max-width: 991px) {
    /* display: block; */
  }
`;

export const Button = styled.div<ButtonProps>`
  transition: color 150ms ease-in-out, border 150ms ease-in-out,
    background 150ms ease-in-out;

  white-space: nowrap;
  text-decoration: none;
  & * {
    text-decoration: none !important;
  }
  display: inline-flex;
  align-items: center;
  /* background: ${({ flat }) =>
    typeof flat !== "undefined"
      ? "transparent"
      : "var(--main-accent-color)"}; */

  font-weight: 700;
  color: var(--main-accent-text-color);

  ${({ flat }) =>
    flat &&
    css`
      color: var(--main-accent-color);
      background: transparent;
    `}


  padding: 1.2em 2em 1.25em 2em;
  font-size: ${({ sm }) => (sm ? "0.7em" : "1em")};
  line-height: 1em;
  border-radius: 0.45em;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid var(--main-accent-color);
  &:hover {
    border: 1px solid var(--main-accent-hover-color);
    background: var(--main-accent-hover-color);
    ${({ flat }) =>
    flat &&
    css`
        color: var(--main-accent-hover-color);
        background: transparent;
      `}
    ${({ ghost }) =>
    ghost &&
    css`
        color: var(--main-accent-hover-color);
        border: 1px solid var(--main-accent-hover-color);
        background: transparent;
      `}
    ${({ disabled }) =>
    disabled &&
    css`
        background: #ccc;
        border-color: #ccc;
        cursor: wait;
      `}
  }

  background: var(--main-accent-color);
  ${({ flat }) =>
    flat &&
    css`
      background: transparent;
      padding: 1em 0em;
      border: 1px solid transparent;
      @media (max-width: 991px) {
        border: 1px solid var(--main-accent-hover-color);
      }
    `}
  ${({ ghost }) =>
    ghost &&
    css`
      color: var(--main-accent-color);
      background: transparent;
      /* padding: 1em 1em; */
    `}
  ${({ sm }) =>
    sm &&
    css`
      padding: 1em 1em;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background: #ccc;
      border-color: #ccc;
      cursor: wait;
    `}
  ${({ black }) =>
    black &&
    css`
      color: black;
    `}
  ${({ round }) =>
    round &&
    css`
      display: flex;
      justify-content: center;
      border-radius: 1000px;
      width: 44px;
      height: 44px;
      padding: 0;
    `}
  ${({ left }) =>
    left &&
    css`
      margin-right: 1em;
    `}
    ${({ secondary }) =>
    secondary &&
    css`
      color: var(--main-text-lighter-color);
      background: var(--main-bg-lighter-color);
      border-color: var(--main-bg-lighter-color);
      &:hover {
        color: var(--main-text-color);
        background: var(--main-bg-lighter-color);
        border-color: var(--main-bg-lighter-color);
      }
    `}
`;

export const CSSmainLayout = styled.div<MainLayoutProps>`
  padding: 0em 1em 0 3em;
  display: grid;
  grid-template-columns: 200px 1fr 280px;
  grid-column-gap: 2em;
  grid-row-gap: 1em;

  @media (max-width: 1280px) {
    padding: 0em 2em;
  }

  @media (max-width: 991px) {
    display: block;
    padding: 0em 1em;
  }

  @media (max-width: 767px) {
  }

  @media (max-width: 479px) {
  }
  ${({ middle }) =>
    middle &&
    css`
      align-items: center;
    `}
`;

export const Line = styled.div<LineProps>`
  margin-bottom: 0.8em;
  ${({ xs }) =>
    typeof xs !== "undefined" &&
    css`
      margin-bottom: 0em;
    `}
  ${({ sm }) =>
    typeof sm !== "undefined" &&
    css`
      margin-bottom: 0.4em;
    `}
  ${({ lg }) =>
    typeof lg !== "undefined" &&
    css`
      margin-bottom: 1.2em;
    `}
  ${({ xlg }) =>
    typeof xlg !== "undefined" &&
    css`
      margin-bottom: 2em;
    `}
`;

export const Text = styled.div<TextProps>`
  line-height: 1.4em;
  /* margin-bottom: 0.8em; */
  ${({ xs }) =>
    typeof xs !== "undefined" &&
    css`
      font-size: 0.7em;
    `}
  ${({ sm }) =>
    typeof sm !== "undefined" &&
    css`
      font-size: 0.9em;
    `}
  ${({ lg }) =>
    typeof lg !== "undefined" &&
    css`
      font-size: 1.2em;
    `}
  ${({ xlg }) =>
    typeof xlg !== "undefined" &&
    css`
      font-size: 2em;
    `}
  ${({ xxlg }) =>
    typeof xxlg !== "undefined" &&
    css`
      font-size: 3em;
    `}
  ${({ opacity }) =>
    typeof opacity !== "undefined" &&
    css`
      opacity: ${opacity};
    `}
  ${({ bold }) =>
    typeof bold !== "undefined" &&
    css`
      font-weight: 600;
    `}
  ${({ color }) =>
    typeof color !== "undefined" &&
    css`
      color: ${color};
    `}
  ${({ center }) =>
    typeof center !== "undefined" &&
    css`
      text-align: center;
    `}
`;

export const Hr = styled.hr`
  background: transparent;
  height: 0px;
  border: none;
  border-bottom: 1px solid var(--main-border-color);
`;

export const MIcon = styled.span<MIconProps>`
  max-width: 2em;
  /* overflow: hidden; */
  margin-top: -2px;
  text-transform: lowercase;
  font-size: ${({ size }) => (size ? `${size}px` : "32px")};
  margin-right: ${({ left }) => (left ? "4px" : "0px")};
  font-family: "Materialicons";
  ${({ color }) =>
    typeof color !== "undefined" &&
    css`
      color: ${color};
    `}
  ${({ left }) =>
    typeof left !== "undefined" &&
    css`
      margin-right: 0.4em;
    `}
  ${({ right }) =>
    typeof right !== "undefined" &&
    css`
      margin-left: 0.4em;
    `}
`;

export const MobHide = styled.div`
  width: 100%;
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const MobShow = styled.div`
  width: 100%;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`;
export const MobHideSpan = styled.span`
  width: 100%;
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const MobShowSpan = styled.span`
  width: 100%;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`;

interface PopCloseProps {
  handle: () => void;
}

export const PopClose: React.FC<PopCloseProps> = ({ handle }) => {
  const CSSclose = styled.div`
    position: absolute;
    left: auto;
    right: -1em;
    top: auto;
    bottom: auto;
    z-index: 1;
    display: flex;
    width: 4em;
    height: 4em;
    justify-content: center;
    align-items: center;
    @media (max-width: 991px) {
      right: 0em;
    }
  `;

  return (
    <CSSclose>
      <Button flat black round>
        <MIcon onClick={handle}>close</MIcon>
      </Button>
    </CSSclose>
  );
};

export const CSSpreloader = styled.div`
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    botton: 0;
    right: 0;
    z-index: 1;
    background-color: green;
    content: ${`""`}; // Fixed this to avoid JSX in template literal
  }
`;

interface PreloaderComponentProps {
  width?: number;
  height?: number;
}

export const Preloader: React.FC<PreloaderComponentProps> = ({ width = 44, height = 44 }) => {
  return (
    <Svg
      style={{ position: "relative", zIndex: 1 }}
      width={width}
      height={height}
      forceHeight={height}
      color="currentColor"
      png={false}
    >
      {preloader}
    </Svg>
  );
};

export const Preloader0 = styled.div<PreloaderProps>`
  height: ${({ height }) => (height ? height : "60vh")};
  display: flex;
  z-index: 100;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Ico = styled.div<IcoProps>`
  font-family: Material Icons, sans-serif;
  line-height: 1em;
  font-size: ${({ size }) => (size ? size : "1.8em")};
`;