import { Input, Modal, Space, Select } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { VideosContext } from "providers/VideosProvider/videosProvider";
import { ColorSuffixSC } from "./styled";
import { fontOptions } from "./constants";
import { ITextData } from "providers/VideosProvider/types";
import uniqid from "uniqid";

interface ITextModalProps {
  isEdit?: boolean;
  isOpen: boolean;
  closeModal: () => void;
  initialTextData: ITextData;
}

export const TextModal: React.FC<ITextModalProps> = React.memo(
  ({ isEdit, isOpen, closeModal, initialTextData }) => {
    const { handles, state } = useContext(VideosContext);
    const [colorVisible, setColorVisible] = useState(false);
    const [bgColorVisible, setBGColorVisible] = useState(false);
    const [textData, setTextData] = useState<ITextData>(initialTextData);

    useEffect(() => {
      if (!isEdit && isOpen) {
        setTextData({
          ...initialTextData,
          startTime: state.currentTime,
          endTime: state.currentTime + 1000,
          length: 1000,
          id: uniqid(),
        });
      }
    }, [isEdit, state.currentTime, isOpen]);

    const hideColorPicker = useCallback(() => {
      setColorVisible(false);
      setBGColorVisible(false);
    }, []);

    const clearModal = useCallback(() => {
      hideColorPicker();
      closeModal();
      setTextData(initialTextData);
    }, [hideColorPicker, closeModal, initialTextData]);

    useEffect(() => {
      return () => {
        clearModal();
        hideColorPicker();
      };
    }, []);

    const handleOk = () => {
      closeModal();
      if (isEdit) {
        handles.changeOneText({ ...textData, endTime: textData.startTime + textData.length });
      } else {
        handles.addNewText({ ...textData, endTime: textData.startTime + textData.length });
      }
    };

    const handleCancel = () => {
      clearModal();
    };

    const handleInputEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value: string | number = e.target.value;
      e.stopPropagation();
      if (e.target.type === "number") {
        value = parseInt(value.replace(/D/g, ""));
      }
      setTextData((prev) => ({ ...prev, [e.target.name]: value }));
    };

    const handleChangeSelect = (value: number) => {
      setTextData((prev) => ({ ...prev, ["fontSize"]: value }));
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.name === "color") {
        setColorVisible(true);
        setBGColorVisible(false);
        return;
      }
      if (e.target.name === "backgroundColor") {
        setBGColorVisible(true);
        setColorVisible(false);
        return;
      }
      hideColorPicker();
    };

    const handleChangeColor = (color: string) => {
      setTextData((prev) => ({ ...prev, color: color }));
    };

    const handleChangeBGColor = (color: string) => {
      setTextData((prev) => ({ ...prev, backgroundColor: color }));
    };

    return (
      <Modal
        title={isEdit ? "Edit text" : "Add Text"}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            name={"text"}
            value={textData.text}
            placeholder="Введите текст"
            onChange={handleInputEvent}
            onFocus={handleFocus}
          />
          <Select
            prefix="шрифт размер"
            style={{ width: "100%" }}
            onChange={handleChangeSelect}
            options={fontOptions}
            onFocus={handleFocus}
          />
          <Input
            type="number"
            prefix="начало:"
            suffix="мc"
            name="startTime"
            value={textData.startTime}
            placeholder="Введите время начала"
            onChange={handleInputEvent}
            onFocus={handleFocus}
          />
          <Input
            type="number"
            prefix="длина:"
            suffix="мc"
            name="length"
            value={textData.length}
            placeholder="Введите длину"
            onChange={handleInputEvent}
            onFocus={handleFocus}
          />
          <Input
            prefix="цвет текста:"
            name="color"
            value={textData.color}
            placeholder="Выберите цвет"
            onChange={handleInputEvent}
            onFocus={handleFocus}
            suffix={<ColorSuffixSC color={textData.color} />}
          />
          {colorVisible && <HexColorPicker color={textData.color} onChange={handleChangeColor} />}
          <Input
            prefix="цвет фона:"
            name="backgroundColor"
            value={textData.backgroundColor}
            placeholder="Выберите цвет"
            onChange={handleInputEvent}
            onFocus={handleFocus}
            suffix={<ColorSuffixSC color={textData.backgroundColor} />}
          />
          {bgColorVisible && (
            <HexColorPicker color={textData.backgroundColor} onChange={handleChangeBGColor} />
          )}
        </Space>
      </Modal>
    );
  },
);
