import React from "react";
import { PreloaderGif } from "./Preloader";

interface SaveButtonProps {
  context: any;
}

export function SaveButton({ context }: SaveButtonProps) {
    return (
      <>
        {!context.state.savingStatus && <div className="x_save x_save--saved"
          style={{ width: '8em' }}
        >
          <div>Сохранено</div>
          <div className="ico ico--20">
            <div>check</div>
          </div>
        </div>}
  
        {context.state.savingStatus && <div className="x_save "
          style={{ width: '8em' }}
        >
          <div style={{ position: 'relative', marginBottom: '-4px', marginTop: '-4px', marginLeft: '-4px', marginRight: '-4px' }}>
            <PreloaderGif style={{ width: 44, height: 44 }} />
          </div>
        </div>}
      </>
    )
  }
