import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Context } from '../contextSubtitles';
import { PreloaderGif, PreloaderTracks } from './Preloader';
import { Pop } from './Pop';
import { DraggingSubtitle } from './DraggingSubtitle';
import { PlayerSubtitles } from './PlayerSubtitles';
import { Track } from 'types';
import { ContentEditable } from './elements/ContentEditable';
import { Ico } from './Uikit';
import { FRAGMENT_MIN_HEIGHT } from './utils';

export const TrackSubtitles = () => {
  const context = useContext(Context);
  const trackRef = useRef<HTMLDivElement | null>(null);
  const zoomLevel = 100 - context.state['zoomLevel'];
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined);
  const [tracks, setTracks] = useState(context.state['translationData']);
  const [editing, setEditing] = useState("");

  const openEditor = (oneTrack: Track) => {
    console.log('openEditor = ');
    context.handles.setPop(oneTrack['id']);
    context.handles.setPlaying();
    context.handles.setPlayingFragment();

    // Clear the timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }

  useEffect(() => {
    if (typeof context.state.scrollTo === 'number' && trackRef.current) {
      trackRef.current.scrollTo({
        left: context.state.scrollTo,
        behavior: 'smooth'
      });
      context.handles.setScrollTo()
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (typeof context.state.scrollTo === 'number' && trackRef.current) {
      trackRef.current.scrollTo({
        left: context.state.scrollTo,
        behavior: 'smooth'
      });
      context.handles.setScrollTo()
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [context.state.scrollTo]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      // context.handles.setPlayingFragment()
    }
  }, [context.state.playStart]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, [context.state.playingFragment]);

  useEffect(() => {
    if (!context.state.playing && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    } else {
      console.log('Not clearing timeout. Playing:', context.state.playing, 'Timeout:', timeoutRef.current);
    }
  }, [context.state.playing]);

  const currentPlayingFragmentRef = useRef(null);

  useEffect(() => {
    currentPlayingFragmentRef.current = context.state.playingFragment;
  }, [context.state.playingFragment]);

  const currentPlayingFragmentNextRef = useRef(null);

  useEffect(() => {
    currentPlayingFragmentNextRef.current = context.state.playingFragmentNext;
  }, [context.state.playingFragmentNext]);

  useEffect(() => {
    if (context.state['translationData'].length) {
      setTracks([...context.state['translationData']])
    }
  }, [context.state.translationData, tracks.length]);

  const updateTrack = (id: string, newData: Track) => {
    let prevTracks = [...tracks];
    let newTracks = prevTracks.map((track) =>
      track.id === id ? { ...track, ...newData } : track
    );
    setTracks(newTracks)
  };

  // hanlde input only when the element is not focused.
  // It happens when the value is changed from find and replace browser addon
  const handleInput = (text: string, focused: boolean, oneTrack: Track) => {
    if (!focused) {
      context.handles.saveCaptionsRus(
        oneTrack,
        text,
        !context.state.lang || context.state.lang === 'ru' ? 'captions_rus' : 'captions'
      );
      setEditing('');
    }
  }

  return (
    <>
      {context.state.pop &&
        <Pop
          oneTrackId={context.state.pop}
          handleClose={() => context.handles.setPop()}
          context={context}
        />
      }

      <div className="x_tracks0"
        style={{
          width: '100%',
          overflow: 'auto',
          position: 'relative'
        }}
      >
        {!context.state['mp3'] && <PreloaderTracks>
          <PreloaderGif />
        </PreloaderTracks>}

        <div
          className={"x_track x_track--v"}
          ref={trackRef}>
          <PlayerSubtitles />

          {
            Boolean(tracks.length) &&
            <div className={'x_track_all_fragments x_track_all_fragments--v'}
              style={{
                display: 'flex',
                width: '100%',
                height:
                  context.state['durationFull'] ? `${context.state['durationFull'] / zoomLevel}px` : 'auto',
                marginLeft: '200px',
              }}>


              <div className="x_track_hover x_track_hover--v" />

              {tracks.map((oneTrack: Track, ind: number) => {
                return (
                  <div>
                    <DraggingSubtitle
                      key={`track_${oneTrack['id']}_${ind}`}
                      oneTrack={oneTrack}
                      ind={ind}
                      id={oneTrack['id']}
                      start={oneTrack['start']}
                      trimmed_start={oneTrack['trimmed_start']}
                      length={oneTrack['end_rus_milli'] - oneTrack['start_rus_milli']}
                      trimmed={oneTrack.trimmed}
                      tracks={tracks}
                      timeoutRef={timeoutRef}
                      openEditor={openEditor}
                      draggingTrack={trackRef}
                    />
                    {context.state.video !== 'full' && 
                      <div 
                        onClick={() => {
                          setEditing(oneTrack.id); 
                        }}
                        style={
                          {
                            top: `${oneTrack['start'] / zoomLevel}px`,
                            height: `${Math.max((oneTrack['end_rus_milli'] - oneTrack['start_rus_milli']) / zoomLevel, FRAGMENT_MIN_HEIGHT)}px`
                          }
                        }
                      className={`x_track_subtitle ${context.state.trackSelected === oneTrack.original_id && "selected"}`}>
                      <ContentEditable
                        className="h-full p-4"
                        handleInput={(text, focused) => handleInput(text, focused, oneTrack)}
                        handleBlur={() => { setEditing("") }}
                        captions={(!context.state.lang || context.state.lang === "ru") ?
                        (oneTrack.captions_rus ?? oneTrack.captions) : oneTrack.captions}
                      />
                      
                      {<div className="xt_textarea_actions">
                        <div className="x_save x_save--cancel" onClick={() => openEditor(oneTrack)}><Ico>edit</Ico></div>
                        <div className="x_save" onClick={ () => context.handles.mergeCaptions(oneTrack)}><Ico>merge</Ico></div>
                      </div>}
                      {oneTrack['trimmed_start'] && <span style={{ color: 'red' }}>⚠️ Начало фрагмента за пределами</span>}   
                    </div>}
                  </div>
                )
              })}
            </div>}

          {!tracks.length && <>
            <div style={{ display: 'flex', rowGap: '1em', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '10vh' }}>
              <div className='ico ico--100'>subtitles_off</div>
              Пока нет субтитров</div>
          </>}

        </div>
      </div>
    </>
  );
}
