import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Ico } from "components/Uikit";
import { HorizontalSlider } from "components/HorizontalSlider";
import { VideosContext } from "providers/VideosProvider/videosProvider";
import { useThrottle } from "hooks/useThrottle";
import { TimeLine } from "../TimeLine";
import {
  TrackAllFragmentsSC,
  TrackAllOrder0SC,
  TrackContainerSC,
  TrackPlayBackSC,
  TracksContainerCS,
  TracksSectionSC,
  ZoomWrapperSC,
} from "./styled";
import { TrackDragging } from "./components/TrackDraging";
import { OrdersButtons } from "./components/OrdersButtons";
import { AddText } from "./components/AddText";

interface ITrackingProps {
  setPlaying: (val: boolean) => void;
}

export const Tracking: React.FC<ITrackingProps> = ({ setPlaying }) => {
  const { state, handles } = useContext(VideosContext);
  const [zoom, setZoom] = useState(state.zoomLevel);
  const zoomLevel = 100 - zoom;
  const trackRef = useRef<HTMLDivElement>(null);
  const [optionsShown, setOptionsShown] = useState(false);
  const throttledValue = useThrottle(zoom, 1000);
  useEffect(() => {
    // context.handles["setZoomLevel"](throttledValue);
  }, [throttledValue]);

  // const blankTrackExist = context.fragments.some((one) => !one.chosenVideo);
  const handleChangeZoomLevel = useCallback((val: number) => {
    // setZoom(val);
    // (val) => context.handles["setZoomLevel"](val);
  }, []);

  return (
    <React.Fragment>
      <TrackContainerSC>
        <ZoomWrapperSC>
          <div className="_fv _fgap1 _fcc">
            <div className="_f">
              <Ico style={{ fontSize: "1.2em" }}>zoom_out</Ico>
              <HorizontalSlider
                value={state.zoomLevel}
                min={10}
                max={90}
                width={200}
                onValueChange={handleChangeZoomLevel}
              />
              <Ico style={{ fontSize: "1.2em" }}>zoom_in</Ico>
            </div>
          </div>
        </ZoomWrapperSC>

        <TracksSectionSC ref={trackRef}>
          <TrackPlayBackSC
            width={state.currentTime ? `${state.currentTime / zoomLevel + 5}px` : "auto"}
          />

          <TimeLine
            zoomLevel={zoomLevel}
            videoDuration={state.videoDuration}
            style={{ zIndex: 100, top: 0, left: "4em" }}
          />

          <TracksContainerCS>
            <TrackAllOrder0SC>
              <OrdersButtons />
              <AddText setPlaying={setPlaying} />
            </TrackAllOrder0SC>

            <TrackAllFragmentsSC
              width={state.videoDuration ? `${state.videoDuration / zoomLevel}px` : "auto"}
            >
              <TrackDragging
                order={0}
                tracks={state.textDataInner}
                // changeOneText={handles.changeOneText}
              />
              <TrackDragging order={1} tracks={state.videoDataInner} isVideo />
            </TrackAllFragmentsSC>
          </TracksContainerCS>
        </TracksSectionSC>
      </TrackContainerSC>
    </React.Fragment>
  );
};
