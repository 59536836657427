import { useCallback, useEffect, useState } from "react";
import { IVideoData, ITextData, ITrack } from "providers/VideosProvider/types";

interface IUseMouseEvents {
  isDragging: boolean;
  isLengthing: boolean;
  oneTrack: ITextData | IVideoData;
  position: number;
  zoomLevel: number;
  videoDuration: number;
  setPosition: (pos: number) => void;
  setLengthPosition: (pos: number) => void;
  lengthPosition: number;
  setIsDragging: (pos: boolean) => void;
  setIsLengthing: (pos: boolean) => void;
  changeOneText: (text: Partial<ITextData>) => void;
}

export const useMouseEvents = ({
  isDragging,
  isLengthing,
  oneTrack,
  position,
  zoomLevel,
  videoDuration,
  setPosition,
  setLengthPosition,
  lengthPosition,
  setIsDragging,
  setIsLengthing,
  changeOneText,
}: IUseMouseEvents) => {
  const [startX, setStartX] = useState(0);
  const [lengthX, setLengthX] = useState(0);
  const [dragStart, setDragStart] = useState(false);
  const [textNewPosition, setTextNewPosition] = useState<ITrack>(() => ({
    id: oneTrack.id,
    startTime: oneTrack.startTime,
    endTime: oneTrack.endTime,
    length: oneTrack.length,
  }));

  const handleMouseMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      let clientX = 0;
      if (e.type === "touchmove") {
        clientX = (e as TouchEvent).touches[0].clientX;
      }
      if (e.type === "mousemove") {
        clientX = (e as MouseEvent).clientX;
      }
      if (isDragging) {
        const { min, max } = getMaxSpace(videoDuration);
        const deltaX = clientX - startX;
        const newPosition = position + deltaX * zoomLevel;

        if (newPosition >= min && newPosition <= max) {
          setPosition(newPosition);
          let newLengthPosition = lengthPosition;

          if (dragStart) {
            newLengthPosition = lengthPosition - deltaX * zoomLevel;
            if (newLengthPosition >= min && newLengthPosition <= max) {
              setLengthPosition(newLengthPosition);
            }
          }

          setStartX(clientX);
          setTextNewPosition((prev) => ({
            ...prev,
            id: oneTrack.id,
            startTime: newPosition,
            endTime: newPosition + newLengthPosition,
            length: newLengthPosition,
          }));
        }
      }
      if (isLengthing) {
        const { min, max } = getMaxLength(videoDuration);
        const deltaX = clientX - lengthX;
        const newLengthPosition = lengthPosition + deltaX * zoomLevel;
        // console.log('newLengthPosition = ', newLengthPosition);
        // The new length of the fragment

        // if (newLengthPosition >= min && newLengthPosition + position <= max) {
        setLengthPosition(newLengthPosition);
        setLengthX(clientX);
        setTextNewPosition((prev) => ({
          ...prev,
          id: oneTrack.id,
          endTime: (prev?.startTime ?? 0) + newLengthPosition,
          length: newLengthPosition,
        }));
      }
    },
    [isDragging, startX, position, lengthPosition, dragStart, isLengthing, lengthX, oneTrack.id],
  );

  useEffect(() => {
    const handleMouseUp = () => {
      if (isDragging || isLengthing) {
        changeOneText(textNewPosition);
      }
      setIsDragging(false);
      setIsLengthing(false);
      setDragStart(false);
    };

    if (isDragging || isLengthing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      // Add touch event listeners
      window.addEventListener("touchmove", handleMouseMove);
      window.addEventListener("touchend", handleMouseUp);
    }

    return () => {
      if (isDragging || isLengthing) {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);

        // Remove touch event listeners
        window.removeEventListener("touchmove", handleMouseMove);
        window.removeEventListener("touchend", handleMouseUp);
      }
    };
  }, [isDragging, isLengthing, position, lengthPosition]);

  return {
    setStartX,
    setDragStart,
    setLengthX,
  };
};

const getMaxLength = (videoDuration: number) => {
  // const isLastTrack = currentIndex === state.textDataInner.length - 1;

  let min = 1000;
  let what = "startTime" as keyof ITextData; //"start";
  // let max = isLastTrack ? state.videoDuration : state.textDataInner[currentIndex + 1][what];
  let max = videoDuration;
  // Correction for the max calculation for the last track
  // if (isLastTrack) {
  //   max = state.videoDuration;
  // }

  return { min, max };
};

const getMaxSpace = (videoDuration: number) => {
  let min = 0;
  let max = videoDuration;
  return { min, max };
};
