import styled from "styled-components";

export const TextSC = styled.div<{ background: string; color: string; fontSize: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize + "px"};
`;
