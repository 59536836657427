import styled from "styled-components";
import { ScaledSvgSC } from "../icons/styled";

export const TrackDragHandleSC = styled.div`
  opacity: 1;
  color: #fff0;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition:
    color 0.2s,
    height 0.2s,
    width 0.2s;

  width: 1em;
  height: 1em;
  background-color: #ff7606;
`;

export const TrackDragIndicatorSC = styled.div`
  z-index: 2;
  cursor: ew-resize;
  justify-content: center;
  align-items: center;
  height: 2em;
  display: flex;
  position: absolute;
  top: -1em;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    ${TrackDragHandleSC} {
      width: 2em;
      height: 2em;
    }
    ${ScaledSvgSC} {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 479px) {
    height: 4em;
    bottom: 0;
  }
`;
