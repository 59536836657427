import React, { useEffect, useState, useRef } from 'react'
import { PopPlayer } from './PopPlayer';
import { PopTools } from './PopTools';
import { getPrevTrackId, saveCaptionsBreak, saveCaptionsBreakSubtitles } from '../utils';

const API_URL = process.env.REACT_APP_API_URL;

export function Pop({ oneTrackId, handleClose, context } ) {
  const subtitleMode = context.state.subtitleMode;
  const lang = context.state.lang;

  const [oneTrack, setOneTrack] = useState()
  const getOneTrack = () => {
    return context.state.translationData.filter(one => one['id'] === oneTrackId)[0]
  };

  const [shown1, setShown1] = useState(false)
  const [shown2, setShown2] = useState(false)
  const [captions, setCaptions] = useState([])
  const [voice, setVoice] = useState()
  const [speed, setSpeed] = useState()
  const [background, setBackground] = useState(false)

  useEffect(() => {
    if (context.state.translationData.length) {
      let oneTrack = getOneTrack(oneTrackId) || {};
      setOneTrack(oneTrack)
      setCaptions(!subtitleMode ? [oneTrack['captions']] : [oneTrack[!lang || lang === 'ru' ? 'captions_rus' : 'captions']])
      setVoice([oneTrack['voice']])
      setSpeed([oneTrack['speed']])
      setBackground([oneTrack['add_background']])
    }
  }, [context.state.translationData, oneTrackId])

  useEffect(() => {
    console.log('background = ', background);
  }, [background])

  useEffect(() => {
    setChanged(false)
  }, [oneTrackId])



  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  const [changed, setChanged] = useState(false)

  useEffect(() => {
    if (
      oneTrack && (
        oneTrack['voice'] !== voice[0] ||
        oneTrack['speed'] !== speed[0] ||
        oneTrack['add_background'] !== background[0] ||
        oneTrack['captions'] !== captions[0])
    ) {
      setChanged(true)
    } else {
      setChanged(false)
    }
  }, [speed, voice, captions, oneTrackId])



  const cols = 79;

  useEffect(() => {
    setShown1(true)
    setTimeout(() => {
      setShown2(true)
      // context.handles.setPopOpen(true)
    }, 5);

  }, [])



  const handleReset = () => {
    setCaptions([oneTrack['captions']])
    setVoice([oneTrack['voice']])
    setSpeed([oneTrack['speed']])
    setBackground([oneTrack['add_background']])
    setChanged(false)
  }

  const handleClosePop = () => {
    setShown2(false)
    setTimeout(() => {
      setShown1(false)
      handleClose(false)
      context.handles.setPop()
    }, 5);
  }

  const removeEdgeLineBreaks = (text) => {
    return text ? text.replace(/^\s*[\r\n]+|[\r\n]+\s*$/g, '') : '';
  };


  const handleSubmit = () => {
    if (captions.length === 1) {
      if (removeEdgeLineBreaks(captions[0]) !== oneTrack['captions']
        || voice !== oneTrack['voice']
        || voice !== oneTrack['speed']
      ) {
        // const newData = {
        //   captions: captions.map(one => removeEdgeLineBreaks(one)),
        //   voice: voice,
        //   speed: speed
        // };
        // console.log('newData = ', newData);
        // updateTrack(oneTrack['id'], newData)
        if (subtitleMode) {
          context.handles.saveCaptionsRus(oneTrack, captions[0], !lang || lang === 'ru' ? 'captions_rus' : 'captions');
        } else {
          saveCaptionsBreak(oneTrack, captions[0], voice[0], speed[0], background[0], context)
        }
      }

    } else {
      // alert('not yet implemented')
      if (subtitleMode) {
        saveCaptionsBreakSubtitles(
          oneTrack,
          captions,
          !lang || lang === 'ru' ? 'captions_rus' : 'captions',
          context,
        )
      } else {
        saveCaptionsBreak(oneTrack, captions, voice, speed, background, context)
      }
    }
  }


  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [textareaRef.current]);

  const processCaptions = (value, index) => {

    let newCaps = []

    if (value === '' || !value) {
      let newCaptions = captions.slice()
      newCaptions.splice(index, 1);
      console.log('newCaptions = ', newCaptions);
      newCaps = newCaptions.length ? newCaptions : [''];
    }

    if (value) {
      if (value.split('\n').length > 0 && value !== captions[0]) {
        const makeBrokenCaptions = (newValue) => {
          let newVal = value.split('\n');
          let newCaptions = captions.slice();
          newCaptions[index] = newVal;
          newCaptions = newCaptions.flat().map(oneCap => oneCap)
          return newCaptions;
        }
        newCaps = makeBrokenCaptions(value)
      } else {
        newCaps = captions.splice().map((one, ind) => index === ind && value)
        console.log('newCaps = ', newCaps);
      }
    }
    setCaptions(newCaps)

  }



  useEffect(() => {
    captions.forEach((one, index) => {
      if (!voice[index]) {
        const newArr = voice.slice()
        newArr[index] = newArr[0]
        setVoice(newArr)
      }
      if (!speed[index]) {
        const newArr = speed.slice()
        newArr[index] = newArr[0]
        setSpeed(newArr)
      }
      if (!background[index]) {
        const newArr = background.slice()
        newArr[index] = newArr[0]
        setBackground(newArr)
      }
    })
  }, [captions])


  const [move, setMove] = useState()
  const [newPop, setNewPop] = useState()
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    if (move) {
      // setOpacity(0)
      setTimeout(() => {
        setMove(0)
      }, 200);

      setTimeout(() => {
        context.handles.setPop(newPop);
        // setOpacity(1);
      }, 400);
    }
  }, [move])




  return (
    <>
      {oneTrack && <div className={shown1 ? "p0 active" : "p0"}>

        <div className="p_arr p_arr--prev" onClick={() => {
          setNewPop(getPrevTrackId(oneTrackId, context.state.translationData))
          setMove(20)
        }}>
          <div className="ico ico--100"><div>navigate_before</div></div>
        </div>

        <div className="p_arr" onClick={() => {
          setNewPop(context.handles.getNextTrackId(oneTrackId))
          // setNewPop(context.handles.setPop(context.handles.getNextTrackId(oneTrackId)))
          setMove(-20)
          // context.handles.setPop(context.handles.getNextTrackId(oneTrackId))
        }}>
          <div className="ico ico--100"><div>navigate_next</div></div>
        </div>



        <div className={shown2 ? "p_b active" : "p_b"}
          style={{
            transform: `translateX(${move}px)`,
            opacity: `${opacity}`,
          }}
        >
          <div className="p_b_top">
            <div className="p_b_title">
              <div className="ico">edit</div>
              {!subtitleMode && <>Изменение перевода</>}
              {subtitleMode && <>Редактирование субтитра</>}
            </div>
            <div className="p_b_x"
              onClick={() => handleClosePop()}
            >
              <div className="ico">
                <div>close</div>
              </div>
            </div>
          </div>
          <div className="p_b_c">


            {!subtitleMode && <div className="p_b_player">

              {context.state.mp3 && <PopPlayer
                spaceBar={isFocused ? false : true}
                id={oneTrack['id']}
                // handleClick={() => {
                //   setLeftPadding(Math.floor(marginLeft / zoomLevel + 40))
                // }}
                mp3={`${API_URL}/${oneTrack['file']}`}
              >
              </PopPlayer>}

            </div>}




            <div className="p_b_text">


              <div className="p_b_textarea00">



                {captions.map((one, index, arr) => {
                  if (one === undefined) {
                    return <div></div>
                  }




                  return (<div
                    className="p_b_textarea0"
                    key={one.id}
                  >
                    {/* {breaks.map((one, ind, arr) => {
                  const tops = [

                  ]
                  return (<>
                    {ind !== arr.length - 1 && <Break key={ind} top={(one + ind) * 30} />}
                  </>)
                })} */}
                    <textarea
                      style={{
                        // width: 'auto',
                        minHeight: captions.length > 1 ? '7em' : '9em',
                      }}
                      cols={cols}
                      ref={textareaRef}
                      value={captions[index]}
                      onChange={(e) => processCaptions(e.target.value, index)}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      maxLength={5000}
                      id="field"
                      name="field"
                      data-name="Field"
                      className="p_b_textarea w-input"
                    />
                    {!subtitleMode && <PopTools
                      handleVoice={(value, ind) => setVoice((prevValue) => prevValue.map((one, index) => index === ind ? value : one))}
                      handleSpeed={(value, ind) => setSpeed((prevValue) => prevValue.map((one, index) => index === ind ? value : one))}
                      handleBackground={(value, ind) => setBackground((prevValue) => prevValue.map((one, index) => index === ind ? value : one))}
                      voice={voice[index]}
                      speed={speed[index]}
                      background={background[index]}
                      index={index}
                    />}
                  </div>)
                })}
              </div>

            </div>


            <div className="p_b_note">
              <div>☝️ Нажатие на Enter (разрыв строки) разобьет фрагмент на два</div>
            </div>
            <div className="p_b_bot">


              <div className="p_b_sub">
                <div className="x_delete"
                  onClick={() => subtitleMode ? context.handles.deleteSubtitle(oneTrack) : context.handles.deleteFragment(oneTrack)}
                ><div className="ico"><div>delete_forever</div></div></div></div>



              {changed && !(context.state.waitingCut.includes(oneTrack.original_id)) && <div className="x_save x_save--cancel"
                onClick={() => handleReset()}
              >
                Сбросить все изменения
              </div>}


              {!(context.state.waitingCut.includes(oneTrack.original_id)) &&
                <button
                  className={changed ? "x_save" : "x_save x_save--cancel"}
                  style={{ opacity: changed ? 1 : 0.3 }}
                  // disabled={!changed}
                onClick={handleSubmit}
              >
                Применить
                </button>}

              {(context.state.waitingCut.includes(oneTrack.original_id)) && <div className={"x_save x_save--cancel"}
                style={{ cursor: 'disabled' }}
                onClick={handleSubmit}
              >
                Ждите...
              </div>}




            </div>
          </div>
          {context.state.waitingCut.includes(oneTrack.original_id) && <div className="p_b_preloader"><img src="https://assets-global.website-files.com/651f0451acd1cf1eb674532d/657223dc690f13a86f06e953_preloader.gif" loading="lazy" className="preloadergif" /></div>}
        </div>



        <div
          onClick={() => handleClosePop()}
          className={shown2 ? "p_z active" : "p_z"} />
      </div>}
    </>
  )
}

export function Break({ top = 0 }) {
  return (
    <div
      style={{ top: top }}
      className="p_b_textarea_break inact">
      {/* <div>-----------------------------------------------</div> */}
      <div className="ico">content_cut</div>
      {/* <div>-----------------------------------------------</div> */}
    </div>
  )
}
