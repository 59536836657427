import React, { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface FolderListProps {
  foldersTotal: number;
  activeFolder: number;
}

export function FolderList({ foldersTotal, activeFolder }: FolderListProps) {
  const folders = [];
  const navigate = useNavigate();
  const location = useLocation();

  const updateFolderParam = (i: number) => {
    // Create a new URLSearchParams object from the current location
    const searchParams = new URLSearchParams(location.search);

    // Update the folder parameter
    searchParams.set('folder', `${i}`);

    // Navigate to the new URL with updated search params
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const renderF = (i: number) => {
    const className = i === activeFolder ? 'x_head_chapter active' : 'x_head_chapter';

    if (
      i === activeFolder ||
      (!(i > activeFolder + 2) && !(i < activeFolder - 2)) ||
      i === foldersTotal ||
      i === foldersTotal - 1 ||
      i === 1 ||
      i === 2
    ) {
      return (
        <span
          onClick={() => updateFolderParam(i)}
          key={`folder_${i}`}
          className={className}
          style={{ cursor: 'pointer' }}
        >
          {i}
        </span>
      );
    } else {
      return <Fragment key={`folder_dot_${i}`}>.</Fragment>;
    }
  };

  for (let i = 1; i <= foldersTotal; i++) {
    folders.push(renderF(i));
  }

  return <>{folders}</>;
}
