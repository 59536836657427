import React, { Ref } from "react";
import { PreloaderGif } from "./Preloader";

interface VideoProps {
  videoRef: Ref<HTMLVideoElement> | undefined;
  videoUrl: string;
  videoMode: boolean;
  className?: string;
}

export const Video = ({ videoRef, videoUrl = '', videoMode = true, className = '' }: VideoProps) => {
  return (<>
    {Boolean(videoUrl) && <div className="_fv _fcc _f100"
      style={{ zIndex: 1, overflow: 'hidden', position: 'absolute' }}
    >
      <PreloaderGif style={{ width: 44, height: 44 }} />
    </div >}

    {
      Boolean(videoMode) &&
      Boolean(videoUrl) && <video
        style={{ zIndex: 2 }}
        ref={videoRef}
        className={className}
        muted
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>}

  </>)
}
