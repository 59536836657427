import React, { useEffect, useRef, useState, useContext } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { VideosContext } from "providers/VideosProvider/videosProvider";
import "react-resizable/css/styles.css";
import { usePreventPauseWithSpace } from "../../hooks/usePreventPauseWithSpace";
import { useIndexedDB } from "hooks/useIndexedDb";
import { Database } from "utils";
import Player from "video.js/dist/types/player";
// @ts-ignore
// import testVideo from "video/0054_Работа_над_ошибками_в_отн.mp4";

interface IPlayerProps {
  playing: boolean;
  setPlaying: (value: boolean) => void;
}
export const DefaultPlayer: React.FC<IPlayerProps> = ({ playing, setPlaying }) => {
  const { state, handles } = useContext(VideosContext);
  const videoRef = useRef<HTMLVideoElement>(null);
  // const trackVideoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<Player>(null);
  // const [currentTime, setCurrentTime] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  // const [videoFromDB, setVideoFromDB] = useState<IVideoIndexDB | null>(null);
  // const { getValue, isDBConnecting } = useIndexedDB("mediaDB");
  const { isDBConnecting, getValue } = useIndexedDB(Database.name);
  usePreventPauseWithSpace();
  const videoUrl =
    "https://flask.dev.transcribe.torsunov.ru/download_rus?file_name=cldirajow000qvs0y7zthzujj/0054_%D0%A0%D0%B0%D0%B1%D0%BE%D1%82%D0%B0_%D0%BD%D0%B0%D0%B4_%D0%BE%D1%88%D0%B8%D0%B1%D0%BA%D0%B0%D0%BC%D0%B8_%D0%B2_%D0%BE%D1%82%D0%BD.mp4";

  useEffect(() => {
    if (!playerRef.current) {
      return;
    }
    if (playing && playerRef.current.paused()) {
      playerRef.current.play();
    }
    if (!playing && !playerRef.current.paused()) {
      playerRef.current.pause();
    }
  }, [playing]);

  const restartVideo = () => {
    if (playerRef.current) {
      playerRef.current.currentTime(0);
    }
  };

  // Initialize video.js player
  useEffect(() => {
    const updateTime = () => {
      if (!playerRef.current || !playerRef.current.el()) {
        console.warn("Attempted to update time on a disposed player.");
        return;
      }
      const newTime = Math.round((playerRef.current?.currentTime() || 0) * 1000);
      // setCurrentTime(newTime);
      if (handles?.setCurrentTime) {
        handles.setCurrentTime(newTime);
      }
    };

    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: "auto",
      });
      playerRef.current.on("loadedmetadata", (event: Event) => {
        event.stopPropagation();

        const duration = Math.round((playerRef.current?.duration() || 0) * 1000);
        setVideoDuration(duration); // Duration in milliseconds
        handles.setVideoDuration(duration);
      });

      playerRef.current.on("timeupdate", updateTime);
      playerRef.current.on("play", () => {
        handles.setVideoPlaying(true);
      });
      playerRef.current.on("pause", () => {
        handles.setVideoPlaying(false);
      });
    }

    return () => {
      if (playerRef.current) {
        // Ensure we remove all event listeners before disposing
        playerRef.current.off("timeupdate");

        if (playerRef.current.el()) {
          playerRef.current.dispose();
        }

        playerRef.current = null;
      }
    };
  }, [videoUrl]);

  // Restart video if context.currentTime is reset to 0
  useEffect(() => {
    if (state.currentTime === 0) {
      restartVideo();
    }
    // setCurrentTime(state.currentTime);
  }, [state.currentTime]);

  return (
    <video
      ref={videoRef}
      className={`video-js vjs-default-skin x_player_video x_player_video--md`}
      style={{ width: "100%", height: "500px" }}
      id={"video-default"}
      key={"video-default"}
    >
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};
