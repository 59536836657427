import styled from "styled-components";

interface Props {
  active: string;
  left: number;
  width: number;
  top?: number;
}

export const TrackContainerSC = styled.div<Props>`
  z-index: ${({ active }) => (active === "true" ? 1 : 0)};
  left: ${({ left }) => (left ? left + "px" : 0)};
  width: ${({ width }) => (width ? width + "px" : "10em")};
  top: ${({ top }) => (top ? top + "em" : "0")};
  border: ${({ active }) => (active === "true" ? "1px solid aliceblue" : 0)};
  opacity: 0.81;
  color: #6e6e6e;
  cursor: pointer;
  background-color: #4b4f70;
  border-radius: 0.5em;
  height: 4em;
  transition:
    color 0.2s,
    background-color 0.2s,
    box-shadow 0.2s,
    opacity 0.2s;
  position: absolute;
  box-shadow:
    0 4px 12px -2px #0003,
    0 1px 3px -1px #0000008f;

  &:hover {
    opacity: 1;
    color: #000;
  }
`;

export const TrackFragmentSC = styled.div<{ draggable: string; top: number }>`
  z-index: -1;
  opacity: ${({ draggable }) => (draggable === "true" ? 1 : 0)};
  background-color: #ff760612;
  height: ${({ top }) => top + "em"};
  transition: opacity 0.2s;
  position: absolute;
  bottom: 1em;
  left: 0;
  right: 0;
  display: ${({ draggable }) => (draggable === "true" ? "block" : "none")};
`;
