import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { removeChange, undoCaptionsTranslate } from '../utils';
import { PlayButton } from './PlayButton';
import { FolderList } from './FolderList';
import { SaveButton } from './SaveButton';
import { Ico } from './Uikit';

const languages = {
  'english': {
    title: 'En',
    titleLong: 'английский'
  },
  'de': {
    title: 'De',
    titleLong: 'немецкий'
  },
  'es': {
    title: 'Es',
    titleLong: 'испанский'
  },
  'chinese': {
    title: '汉',
    titleLong: 'китайский'
  },
}


export function Head({
  playShown = true,
  playSpacebar = true,
  titleShown = true,
  foldersShown = true,
  languageShown = true,
  saveShown = 'changed',
  videoToggleShown = true,
  context,
}) {

  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (value) => {
    context.handles.setToLang(value);
  }

  const fixName = (name) => {
    if (name && typeof name === 'string') {
      name = name.replaceAll('_', ' ')
      return name
    } else {
      return 'Лекция без имени'
    }
  }

  const fixLang = (lang) => {
    if (!lang || !languages[lang]) return;
    return `Рус → ${languages[lang]['title']}`;
  }

  const handleVideo = () => {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    if (context.state.video) {
      // Remove the 'video' parameter if it exists
      searchParams.delete('video');
      context.handles.setVideo()
    } else {
      // Add the 'video' parameter
      searchParams.set('video', '1');
      context.handles.setVideo('sm')
    }

    // Construct the new path
    const newPath = `${location.pathname}?${searchParams.toString()}`;
    navigate(newPath);
  };

  const undoChange = () => {
    const found = context.state[saveShown]
    .findLast(item => item.action === "text" && item.id.startsWith(`${context.state.folder}_`));
    const foundId = found.id.split("_");
    undoCaptionsTranslate(
      {
        id: found.id,
        folder_num: foundId[0],
        original_id: foundId[1], 
      },
      found.prevText,
      context
    );
    removeChange(found, "changedTranslation", context);
  }


  return (
    <>
      <div className="x_head0">
        <div className="x_head_border" />
        <div className="x_head_l">
          <div className="x_head_logo">
            {playShown && <div className="x_player_control">
              <PlayButton
                playSpacebar={playSpacebar}
                enter={context.state.pop ? false : true} context={context} />
            </div>}

            {titleShown && <div className="x_head_logo_text ellipsis">{fixName(context.state['name'])}</div>}

            {foldersShown && <div className="x_head_chapters0">
              <FolderList
                // link={`/${context.state['mediaId']}?name=${context.state['name']}${context.state['lang'] ? `&lang=${context.state['lang']}` : ''}${context.state['video'] ? '&video=1' : ''}`}
                foldersTotal={context.state.foldersTotal}
                activeFolder={parseInt(context.state.folder)}
              />
            </div>}

            {languageShown && <div className="x_head_logo_lang">{fixLang(context.state['lang'])}</div>}
            {/* {languageShown && <div className="x_head_logo_lang">{context.state['lang']}</div>} */}

          </div>
          <div className='x_head_center'>

          </div>
        </div>


        <div className="x_head_r">
        {location.pathname.includes("translate") &&
          <a href={`/subtitles/${context.state['mediaId']}?folder=${context.state['folder']}&${context.state['lang'] ? `lang=${context.state['lang']}` : ''}`}>Субтитры</a>}
        {context.state.subtitleMode && <>
        <select
          style={{ width: '180px' }}
          value={context.state.toLang}
          onChange={(e) => {
            handleLanguageChange(e.target.value)
          }}

        >
          {Object.keys(languages).map(val => {
            return <option value={val}>{languages[val].titleLong}</option>
          })
          }
        </select>
        {!context.state.lang || context.state.lang === "ru" ?
        <>
          <button onClick={() => context.handles.metadataTranslate(context.state.folder, context.state.toLang)}>
            Создать перевод (использует платные ресурсы)
          </button>
          {location.pathname.includes("subtitles") &&
            <a href={`/translate/${context.state['mediaId']}?folder=${context.state['folder']}&${context.state['lang'] ? `lang=${context.state['lang']}` : ''}`}>Перевод</a>}
        </> :
        <>
          <button onClick={() => context.handles.audioSynthesize(null, context.state.folder, context.state.mediaId, context.state.lang)}>
            Создать озвучку (использует платные ресурсы)
          </button>
          {location.pathname.includes("subtitles") &&
          <a href={`/${context.state['mediaId']}?folder=${context.state['folder']}&${context.state['lang'] ? `lang=${context.state['lang']}` : ''}`}>Озвучка</a>}
        </>
        }
        </>}

          {saveShown && <>
            <button 
              onClick={undoChange}
              title="Отменить изменение"
              disabled={
                context.state.savingStatus ||
                context.state[saveShown]
                .filter(item =>
                  item.action === "text"
                  && item.id.startsWith(`${context.state.folder}_`)
                ).length === 0
              }
            >
              <Ico>undo</Ico>
            </button>
            <div className="x_changes_count">
              <div className="x_flex-h">
                <div>Изменений:</div>
                <strong>{context.state[saveShown].length}</strong>
              </div>
            </div>

            <SaveButton context={context} />
          </>}


          {/* <div >
            <div className={`x_player_video_set_1 ${context.state.russian ? 'active' : ''}`}
              // onClick={() => context.handles.setVideo(context.state.video ? false : 'sm')}
              onClick={() => {
                context.handles.setRussian(r => !r)
              }}
            >
              Ру
            </div>
          </div> */}

          {videoToggleShown && <div >
            <div className={`x_player_video_set_1 ${context.state.video ? 'active' : ''}`}
              // onClick={() => context.handles.setVideo(context.state.video ? false : 'sm')}
              onClick={() => {
                handleVideo()
              }}
            >
              <div className="ico">
                videocam
              </div>
            </div>
          </div>}


        </div>



      </div>

    </>
  )
}
