import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../context';
import { getFolderStartTime } from '../utils';
import { TimeNumbers } from './TimeNumbers';
import { VoicingSilence } from './Voicing/VoicingSilence';
import { Track } from 'types';
import { ReactWaves } from './ReactWaves';

export function PlayerMain() {
  const wavesContainerRef = useRef(null);
  const context = useContext(Context);

  const zoomLevel = 100 - context.state['zoomLevel'];

  const [wavesurfer, setWavesurfer] = useState<any>(null);
  const [pos, setPos] = useState(0);
  const [playbackTime, setPlaybackTime] = useState(0);


  const onLoading = ({ wavesurfer }: any) => {
    setWavesurfer(wavesurfer);
  }

  const onPosChange = (newPos: number) => {
    if (newPos !== pos) {
      setPos(newPos);
    }
  };

  const handleAudioProcess = (() => {
    let lastCall = 0;
    return () => {
      const now = Date.now();
      if (now - lastCall >= 100) {
        lastCall = now;
        let time = wavesurfer.getCurrentTime() * 1000;
        setPlaybackTime(time);
      }
    };
  })();

  useEffect(() => {
    if (context.state.voicingSpacesOn) {
      const found = (context.state.voicingSpaces as Track[])
        .filter(one => !context.state.voicingSpacesDeleted.includes(one.nextTrackId))
        .find(item => playbackTime > item.start && playbackTime < item.end);
      if (found) {
        wavesurfer.seekTo(found.end / context.state.audioDuration);
      }
    }
  }, [playbackTime]);

  useEffect(() => {
    if (wavesurfer && context.debug.debugFullFunctionality) {
      wavesurfer.on('audioprocess', () => {
        handleAudioProcess()
      });
    }

    return () => {
      if (wavesurfer) {
        wavesurfer.un('audioprocess', handleAudioProcess);
      }
    };
  }, [wavesurfer]);

  useEffect(() => {
    if (wavesurfer && typeof context.state.playStart === 'number') {
      const duration = context.state.audioDuration;
      const newStart = context.state.playStart / duration
      if (newStart <= 1 && newStart >= 0) {
      wavesurfer.seekTo(newStart);
      }
      context.handles.setPlayStart()
    }
  }, [context.state.playStart])


  const seek = (position: number) => {
    let currentTime = position * wavesurfer.getDuration();
    console.log('currentTime = ', currentTime);
    context.handles.setPlayStart(currentTime * 1000);
    if (context.state.translationDataAll) {
      const startTime = getFolderStartTime(context.state.translationDataAll, context.state.folder - 1);
      if (startTime) {
        context.handles.setPlayOriginalStart(startTime + currentTime * 1000);
      }
    }
  }


  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('audioprocess', handleAudioProcess);
      wavesurfer.on('seek', seek);
    }
    return () => {
      if (wavesurfer) {
        wavesurfer.un('audioprocess', handleAudioProcess);
      }
    };
  }, [wavesurfer, context.debug.debugFullFunctionality])

  const wavesurferRef = useRef(null);
  const width = context.state['audioDuration'] ? `${context.state['audioDuration'] / zoomLevel}px` : 'auto'

  return (<>
    {context.debug.debugFullFunctionality && <TimeNumbers playbackTime={playbackTime} />}

    <div className="x_track_playback inact" style={{
      zIndex: 10,
      width: playbackTime ? `${playbackTime / zoomLevel + 5}px` : 'auto',
      mixBlendMode: 'multiply'
    }} />

    <div className={context.state.subtitleMode ? "x_player0 x_player0--v" : "x_player0"}
      style={{
        width: width
      }}
    >
      <div className='x_player'>
        <VoicingSilence />
        <div className="" ref={wavesContainerRef}
          style={{
            overflowY: 'hidden',
            width: context.state['audioDuration'] ? `${context.state['audioDuration'] / zoomLevel}px` : 'auto',
            whiteSpace: 'nowrap',
            borderRight: context.state.durationFull > context.state.audioDuration ? '2px dotted red' : 'none',
          }}
        >
          {/*<div style={{
            top: 0, bottom: 0, left: 0, right: 0, zIndex: 1, position: 'absolute',
          }} />*/}

          {context.state.mp3 && <ReactWaves
            ref={wavesurferRef}
            audioFile={context.state['mp3']}
            className={'react-waves'}
            options={{
              cursorWidth: 0,
              height: 200,
              hideScrollbar: true,
              progressColor: '#ff7606',
              responsive: false,
              waveColor: '#D1D6DA',
              backend: "MediaElement",
              audioRate: context.state.playbackRate
            }}
            zoom={(100 - context.state.zoomLevel) / 100}
            volume={context.state['volumeOriginal'] / 100}
            pos={pos}
            playing={context.state['playing']}
            onPosChange={onPosChange}
            onLoading={onLoading}
          >
          </ReactWaves>}
        </div>
      </div>
    </div>


  </>
  );
}
