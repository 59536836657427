import React, { forwardRef } from 'react';
import ReactWaves_ from '@dschoon/react-waves';

interface ReactWavesProps {
  audioFile: string;
  className: string;
  options: any;
  volume: number;
  zoom: number;
  pos: number;
  playing: boolean;
  onLoading: (data: any) => void;
  onPosChange?: (pos: number) => void;
  onFinish?: () => void;
  children?: React.ReactNode;
}

// Cast the imported component to a type that accepts refs.
const ReactWavesComponent = ReactWaves_ as unknown as React.ComponentType<
  ReactWavesProps & React.RefAttributes<any>
>;

export const ReactWaves = forwardRef<any, ReactWavesProps>((props, ref) => {
  return <ReactWavesComponent {...props} ref={ref} />;
});

ReactWaves.displayName = 'ReactWaves';
