import { ISelectOptions } from "types/common";

export const fontOptions: ISelectOptions[] = [
  { value: 8, label: "8pt" },
  { value: 10, label: "10pt" },
  { value: 12, label: "12pt" },
  { value: 14, label: "14pt" },
  { value: 16, label: "16pt" },
  { value: 18, label: "18pt" },
  { value: 20, label: "20pt" },
  { value: 22, label: "22pt" },
  { value: 24, label: "24pt" },
  { value: 26, label: "26pt" },
];
