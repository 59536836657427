import React, { ReactNode, useEffect, useState } from "react";

import main_lecture from "../../video/main_lecture_AI_processing.json";
import {
  ITextData,
  IVideoData,
  IVideoProvider,
  IVideoHandles,
  IVideoProcessingType,
} from "./types";
import { TEXT_DATA_KEY, VIDEO_DATA_KEY } from "./constants";
import { parseVideoIIOptions } from "./utils";

const VideosContext = React.createContext<IVideoProvider>({} as IVideoProvider);

const VideosProvider = ({ children }: { children: ReactNode }) => {
  const [video, setVideo] = useState<string>("md");
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [zoomLevel, setZoomLevel] = useState<number>(80);
  const [menuVisible, setMenuVisible] = useState<boolean>(true);
  const [videoAiOptions, setVideoAiOptions] = useState<IVideoProcessingType>();
  const [textDataInner, setTextDataInner] = useState<ITextData[]>([]);
  const [videoDataInner, setVideoDataInner] = useState<IVideoData[]>([]);
  const [currentVideo, setCurrenVideo] = useState<IVideoData | null>(null);
  const [videoPlaying, setVideoPlaying] = useState(false);

  useEffect(() => {
    //TODO: сделать загрузку с сервера
    setVideoAiOptions(main_lecture);
    setVideoDataInner(parseVideoIIOptions(main_lecture));
  }, []);

  useEffect(() => {
    if (videoAiOptions) {
      // TODO change for right menu it shoud be object not array
      setTextData(getDataFromLocal<ITextData>(TEXT_DATA_KEY));
    }
    const localVideoData = getDataFromLocal<IVideoData>(VIDEO_DATA_KEY);
    if (localVideoData.length) {
      setVideoData(getDataFromLocal<IVideoData>(VIDEO_DATA_KEY));
    }
  }, [videoAiOptions]);

  useEffect(() => {
    const currentVideo = videoDataInner.filter(
      (video) => currentTime >= video.startTime && currentTime < video.endTime,
    );
    if (currentVideo.length) {
      setCurrenVideo(currentVideo[0]);
    } else {
      setCurrenVideo(null);
    }
  }, [currentTime]);

  function tryParse<T>(string: string) {
    try {
      return JSON.parse(string) as T;
    } catch (error) {
      console.log("wrong format");
      return false;
    }
  }

  function getDataFromLocal<T>(key: string) {
    let d = localStorage.getItem(key);
    if (d !== null) {
      return tryParse<T[]>(d) || [];
    }
    return [] as T[];
  }

  const setTextData = (newData: ITextData[] = []) => {
    setTextDataInner(newData);
    localStorage.setItem(TEXT_DATA_KEY, JSON.stringify(newData));
  };

  const setVideoData = (newData: IVideoData[] = []) => {
    setVideoDataInner(newData);
    localStorage.setItem(VIDEO_DATA_KEY, JSON.stringify(newData));
  };

  const updateTextDataList = (newData: ITextData[]) => {
    setTextData(newData);
  };

  const addNewText = (newLane: ITextData) => {
    setTextData([newLane, ...textDataInner]);
  };

  const removeTextById = (id: string) => {
    setTextData([...textDataInner.filter((one) => one.id !== id)]);
  };

  const changeOneText = (newData: Partial<ITextData>) => {
    const newTextData = textDataInner.map((one: ITextData) => {
      let newD = {};
      if (newData.id === one.id) {
        newD = { ...newData };
      }
      return { ...one, ...newD };
    });
    setTextData(newTextData);
  };

  const changeOneVideoData = (newData: Partial<IVideoData>) => {
    const newVideoData = videoDataInner.map((one: IVideoData) => {
      let newD = {};
      if (newData.id === one.id) {
        newD = { ...newData };
      }
      return { ...one, ...newD };
    });
    setVideoData(newVideoData);
  };

  //
  const handles: IVideoHandles = {
    setZoomLevel,
    setVideo,
    setVideoDuration,
    setCurrentTime,
    setMenuVisible,
    addNewText,
    removeTextById,
    changeOneText,
    changeOneVideoData,
    updateTextDataList,
    setVideoPlaying,
  };

  return (
    <VideosContext.Provider
      value={{
        state: {
          video,
          currentTime,
          zoomLevel,
          videoDuration,
          textDataInner,
          videoDataInner,
          videoAiOptions,
          menuVisible,
          currentVideo,
          videoPlaying,
        },
        handles,
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};

const VideosConsumer = VideosContext.Consumer;

export { VideosContext, VideosProvider, VideosConsumer };
