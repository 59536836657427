import React from 'react';
import { HorizontalSlider } from "./HorizontalSlider";

interface PopToolsProps {
  speed: number;
  voice: "male" | "female"; 
  background: boolean;
  handleVoice: (value: string, index: number) => void;
  handleBackground: (checked: boolean, index: number) => void;
  handleSpeed: (value: number, index: number) => void;
  index: number;
}

export function PopTools({
    speed = 1,
    voice = 'male',
    background = false,
    handleVoice,
    handleBackground,
    handleSpeed,
    index }: PopToolsProps) {
  
    const rounded = (number: number) => Math.floor(number * 10) / 10;
  
    return (
      <div className="p_b_tools">
        <div className="p_b_voice0">
          <div>Голос:</div>
          {/* <div className={voice === 'male' ? "p_b_voice active" : "p_b_voice"}
            onClick={() => handleVoice('male', index)}
          >
            <div>Олег Геннадиевич</div>
          </div>
          <div className={voice === 'female' ? "p_b_voice active" : "p_b_voice"}
            onClick={() => handleVoice('female', index)}
          >
            <div>Аудитория</div>
          </div> */}
  
          <select
            style={{ width: '180px' }}
            value={voice}
            onChange={(e) => {
              handleVoice(e.target.value, index)
            }}
  
          >
            <option value="male">Олег Торсунов</option>
            <option value="male1">Аудитория: Мужской</option>
            <option value="female">Аудитория: Женский</option>
            <option value="none">Без перевода</option>
          </select>
  
  
  
        </div>
  
  
        <div className="p_b_speed">
          <div>Стаблильность (уменьшение - больше эмоций, уменьшение - больше стабильности):</div>
          <HorizontalSlider
            value={speed * 100}
            min={60}
            max={120}
            width={200}
            onValueChange={(val: number) => handleSpeed(rounded(val / 100), index)}
          />
          <b>{rounded(speed - 0.3)}</b>
        </div>
  
        <div className="p_b_speed">
          <label>Звуковой фон: <input type='checkbox' checked={background} onChange={(e) => {
            console.log('e = ', e.target.checked);
            handleBackground(e.target.checked, index)
          }
          } /></label>
  
        </div>
  
  
      </div>
    )
  }
