import { IVideoData, IVideoProcessingType } from "./types";
import uniqid from "uniqid";
export const parseVideoIIOptions = (option: IVideoProcessingType) => {
  const data: IVideoData[] = [];
  option.subtopics.forEach((sub) => {
    sub.first_response.forEach((first) => {
      const startTime = parseTimeToMilliseconds(first.start_time);
      const endTime = parseTimeToMilliseconds(first.end_time);
      data.push({
        id: uniqid("video"),
        type: "video",
        startTime: parseTimeToMilliseconds(first.start_time),
        endTime: parseTimeToMilliseconds(first.end_time),
        length: endTime - startTime,
        title: first.topic_title,
        position: {
          x: 100,
          y: 100,
        },
        size: {
          width: 100,
          height: 100,
        },
        depiction: first.character_depiction,
      });
    });
  });
  return data;
};

function parseTimeToMilliseconds(timeString: string) {
  // Split the string into time and milliseconds
  const [time, milliseconds] = timeString.split(",");

  // Split the time into hours, minutes, and seconds
  const [hours, minutes, seconds] = time.split(":");

  // Convert each part to milliseconds
  const hoursInMs = parseInt(hours, 10) * 60 * 60 * 1000;
  const minutesInMs = parseInt(minutes, 10) * 60 * 1000;
  const secondsInMs = parseInt(seconds, 10) * 1000;
  const ms = parseInt(milliseconds, 10);

  // Sum all parts to get the total milliseconds
  return hoursInMs + minutesInMs + secondsInMs + ms;
}
