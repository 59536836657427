import React, { useEffect, useContext, useCallback, EventHandler, KeyboardEvent } from "react";
import { VideosContext } from "providers/VideosProvider/videosProvider";

interface IVideosHeadProps {
  setPlaying: (val: boolean) => void;
  playing: boolean;
}

export const VideosHead: React.FC<IVideosHeadProps> = ({ setPlaying, playing }) => {
  const { state, handles } = useContext(VideosContext);

  const handlePlay = useCallback(() => {
    console.log("handlePlay", state.videoPlaying);
    setPlaying(!state.videoPlaying);
  }, [setPlaying, state.videoPlaying]);

  useEffect(() => {
    const handleKeyPress = (e: Event) => {
      console.log("handleKeyPress", e);
      if ((e as unknown as KeyboardEvent).key === " ") {
        console.log("press space");
        // Spacebar key
        e.preventDefault();
        handlePlay();
      }
    };
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [playing, handlePlay]);

  return (
    <div className="_f" style={{ position: "absolute", top: "1em", left: "3em", zIndex: 25 }}>
      <div className="x_player_control_play" onClick={handlePlay}>
        <div className="ico ico--40">
          <div>{state.videoPlaying ? "pause" : "play_arrow"}</div>
        </div>
      </div>

      <div
        className="x_player_control_play"
        onClick={() => {
          handles.setCurrentTime(0);
        }}
      >
        <div className="ico ico--40">
          <div>restart_alt</div>
        </div>
      </div>
    </div>
  );
};
