import React from "react";
import { IIconProps } from "../types";
import { ScaledSvgSC } from "../styled";

export const TrackDragIcon: React.FC<IIconProps> = ({ width, height }) => {
  let w = width || 20;
  let h = height || 14;
  return (
    <ScaledSvgSC
      width={w}
      height={h}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z"
        fill="white"
      />
    </ScaledSvgSC>
  );
};
