import React, { useEffect, useState, useContext } from "react";

import { VideosContext } from "providers/VideosProvider/videosProvider";
import "react-resizable/css/styles.css";
import { Overlays } from "./components/Overlays";
import { VideoContainerSC } from "./styled";
import { usePreventPauseWithSpace } from "./hooks/usePreventPauseWithSpace";
import { useIndexedDB } from "../../../../../hooks/useIndexedDb";
import { Database } from "../../../../../utils";
import { TrackPlayer } from "./components/TrackPlayer";
import { DefaultPlayer } from "./components/DefaultPlayer";
import { IVideoIndexDB } from "../../../../../types/common";
import { VIDEO_PLAYERS_CONTAINER_ID } from "./constants";
// @ts-ignore
// import testVideo from "video/0054_Работа_над_ошибками_в_отн.mp4";

interface IPlayerProps {
  playing: boolean;
  setPlaying: (value: boolean) => void;
}
export const VideoPlayer: React.FC<IPlayerProps> = ({ playing, setPlaying }) => {
  const { state, handles } = useContext(VideosContext);
  const [videoFromDB, setVideoFromDB] = useState<IVideoIndexDB | null>(null);

  const { isDBConnecting, getValue } = useIndexedDB(Database.name);
  usePreventPauseWithSpace();
  const videoUrl =
    "https://flask.dev.transcribe.torsunov.ru/download_rus?file_name=cldirajow000qvs0y7zthzujj/0054_%D0%A0%D0%B0%D0%B1%D0%BE%D1%82%D0%B0_%D0%BD%D0%B0%D0%B4_%D0%BE%D1%88%D0%B8%D0%B1%D0%BA%D0%B0%D0%BC%D0%B8_%D0%B2_%D0%BE%D1%82%D0%BD.mp4";
  const [showTrackPlayer, setShowTrackPlayer] = useState(false);

  useEffect(() => {
    console.log("state.currentVideo", state.currentVideo);
    const getVideo = async () => {
      const data = await getValue(Database.videoTable, state.currentVideo?.indexDBId || 0);
      if (data) {
        setVideoFromDB(data as IVideoIndexDB);
      }
    };

    if (state.currentVideo?.indexDBId) {
      void getVideo();
    } else {
      setVideoFromDB(null);
    }
  }, [state.currentVideo]);

  return (
    <VideoContainerSC>
      {Boolean(videoUrl) && (
        <div
          className="video-js-container"
          style={{ zIndex: 2, width: "100%" }}
          id={VIDEO_PLAYERS_CONTAINER_ID}
        >
          <DefaultPlayer playing={playing} setPlaying={setPlaying} />
          <TrackPlayer
            video={videoFromDB}
            isFinished={!state.currentVideo?.indexDBId}
            videoPlaying={state.videoPlaying}
            playing={playing}
          />

          <Overlays setPlaying={setPlaying} />
        </div>
      )}
    </VideoContainerSC>
  );
};
