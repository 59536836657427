import React, { useContext } from "react";
import { VideosContext } from "providers/VideosProvider/videosProvider";
import { ContainerSC, DebugTextSC } from "./styled";

export const DebugVideo = () => {
  const { state } = useContext(VideosContext);
  return (
    <ContainerSC>
      <DebugTextSC>Duration (ms):{state.videoDuration}</DebugTextSC>
      <DebugTextSC>Current time (ms):{state.currentTime}</DebugTextSC>
      <DebugTextSC>
        <div
          className="x_button x_button--ghost _t08"
          onClick={() => {
            localStorage.clear();
          }}
        >
          localStorage.clear()
        </div>
      </DebugTextSC>
    </ContainerSC>
  );
};
